<template>
  <ReportPanel class="report-year">
    <template #title>
      年度销售分析
    </template>
    <template #header>
      <el-tabs
        v-model="sareaBm"
        style="margin-top: 9px;"
        :stretch="true"
        @tab-click="changeTab"
      >
        <el-tab-pane
          v-for="(item,index) in tabStatus"
          :ref="'tab' + item.value"
          :key="index"
          :label="item.text"
          :name="item.value"
        />
      </el-tabs>
    </template>
    <div class="chart-year">
      <div class="chart-year__legend">
        <span class="pos_left">万元</span>
        <span class="item"><i class="icon-circle" />{{ nyearList[0] }}</span>
        <span class="item"><i class="icon-circle icon-circle-cur" />{{ nyearList[1] }}</span>
        <!-- <span class="item">同比增长：{{rise}}%</span> -->
      </div>
      <div
        id="chartYear"
        class="chart-year__container"
      />
    </div>
  </ReportPanel>
</template>
<script>
import * as echarts from 'echarts';
import { getAnnuaSales } from '../../api/report';
import ReportPanel from './ReportPanel';

export default {
  name: 'AnnualSales',
  components: {
    ReportPanel,
  },
  data() {
    return {
      tabStatus: [{
        text: '全部',
        value: 'ALL',
      },
      {
        text: '华北东北',
        value: 'W',
      },
      {
        text: '华东华中',
        value: 'N',
      },
      {
        text: '华南西部',
        value: 'O',
      },
      ],
      sareaBm: 'ALL',
      myChart: null,
      Annuadata: [],
      NowList: [],
      PassList: [],
      maxNum: 0,
      rise: 0,
      yearList: [],
      nyearList: [],
    };
  },
  mounted() {
    this.queryAnnSales();
    // this.createChart();
    window.addEventListener('resize', () => {
      this.myChart.resize();
    });
  },
  methods: {
    changeTab(tab) {
      const { name } = tab;
      this.sareaBm = name;
      this.queryAnnSales();
    },
    createChart() {
      const chartDom = document.getElementById('chartYear');
      this.myChart = echarts.init(chartDom);
      const option = {
        grid: [{
          // left: '12%',
          // // top: '12%',
          // top: '12%',
          // bottom: '10%',
          // width: '82%',

          left: '68',
          top: '15',
          bottom: '36',
          right: '24',
        }],
        // title: {
        //   text: `同比增长: ${this.rise}%`,
        //   left: '75%',
        //   textStyle: {
        //     fontFamily: 'Microsoft YaHei',
        //     fontSize: 14,
        //     fontStyle: 'normal',
        //     fontWeight: 'normal',
        //   },
        //   padding: 11,
        // },
        //
        color: ['#7E80F8', '#53D5E0'],
        tooltip: {
          formatter(params) {
            return `<p>${params.seriesName}</p>
            <p>${params.marker}<span style="margin-right:10px;">${params.name}</span>${params.data}
            </p>`;
          },
        },
        // 图例-每一条数据的名字叫销量
        // legend: {
        //   left: '50%',
        //   top: '0.5%',
        //   padding: 11,
        //   itemHeight: 10,
        //   itemWidth: 10,
        //   data: [{
        //       name: '2020',
        //       icon: 'image:// https://zydmall-brand-site.oss-cn-shenzhen.aliyuncs.com/brands/purple_icon.png',
        //     },
        //     {
        //       name: '2021',
        //       icon: 'image:// https://zydmall-brand-site.oss-cn-shenzhen.aliyuncs.com/brands/green_icon.png',
        //     },
        //   ],
        // },
        // x轴
        xAxis: {
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisLine: {
            show: true, // 隐藏x轴轴线
            lineStyle: {
              color: ' #EAEAEA ',
              width: 2,
              type: 'solid',
            },
          },
          axisLabel: {
            color: '#333333',
          },
          axisTick: {
            show: false, // 隐藏坐标轴的刻度线
            alignWithLabel: true, // 图形于标题居中
            inside: true, // 刻度线是指向内部还是外部
            length: 0, // x轴上指向标题刻度线的长度
          },
        },
        // y轴没有显式设置，根据值自动生成y轴
        yAxis: {
          splitLine: {
            show: false,
          },
          min: 0, // 就是这两个 最小值
          // max: this.maxNum, // 最大值
          splitNumber: 5, // 设置y轴刻度间隔个数
          splitArea: { show: false },
          axisLine: {
            show: true, // 隐藏x轴轴线
            lineStyle: {
              color: ' #EAEAEA ',
              width: 2,
              type: 'solid',
            },
          },
          axisLabel: {
            color: '#333333',
          },
        },
        // 数据-data是最终要显示的数据
        series: this.yearList,
      };

      option && this.myChart.setOption(option);
    },
    queryAnnSales() {
      getAnnuaSales(this.sareaBm).then((res) => {
        this.Annuadata = res.data;
        const NowVO = res.data.annualSalesNowVO;
        const PassVO = res.data.annualSalesPassVO;
        const arrList = [];
        const arrList2 = new Array(12).fill(0);
        const nyearList = [PassVO[0].nyear, NowVO[0].nyear];
        this.nyearList = nyearList;
        for (const key in NowVO) {
          // arrList.push(parseInt(((NowVO[key].ntotalAmount) / 10000).toFixed(0), 10));
          arrList.push(((NowVO[key].ntotalAmount) / 10000).toFixed(2));
        }
        for (const key in PassVO) {
          // arrList2[PassVO[key].nmonth - 1] = parseInt(((PassVO[key].ntotalAmount) / 10000).toFixed(0), 10);
          arrList2[PassVO[key].nmonth - 1] = ((PassVO[key].ntotalAmount) / 10000).toFixed(2);
        }
        this.NowList = arrList;
        this.PassList = arrList2;
        const yearList = [];
        if (PassVO) {
          yearList.push({
            name: PassVO[0].nyear,
            type: 'line',
            data: this.PassList,
            symbol: 'circle',
            symbolSize: 10,
          });
        }
        if (NowVO) {
          yearList.push({
            name: NowVO[0].nyear,
            type: 'line',
            data: this.NowList,
            symbolSize: 8,
          });
        }
        this.yearList = yearList;
        // const maxNum1 = Math.max(...arrList);
        // const maxNum2 = Math.max(...arrList2);
        this.rise = (res.data.rise * 100).toFixed(2);
        this.createChart();
      })
    },
  },
};

</script>
<style
  scoped
  lang="scss"
>
.panel-report {}

.report-year {
  position: relative;
  height: 100%;

  /*
  .eh-title {
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.86);
    border-radius: 10px;
    padding: 12px 15px 0px 15px;

    .ft {
      position: relative;
      font-size: 18px;
      font-weight: bold;
      color: #01B0B0;
      padding-left: 7px;
      top: 7px;
    }
  }
 */
  /*::v-deep .el-tabs {
    margin-top: 0 !important;
    padding-top: 8px !important;

    box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.86);

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: 30px;
    }

    .el-tabs__header {
      margin: 0 10px !important;

      .el-tabs__nav-wrap {
        &::after {
          display: none;
        }
      }
    }

    .el-tabs__item {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
  }*/

  /*.eh-chart-content {
    padding: 0px 24px 0px 0px;
    width: 100%;
    height: 280px;
    box-sizing: border-box;
  }*/
}

/*.eh-chart-content {
  padding: 0px 24px 0px 0px;
  width: 100%;
  height: 280px;
  box-sizing: border-box;
}*/
.chart-year {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.chart-year__legend {
  position: relative;
  flex: none;
  /*margin: 12px 0 0;*/
  padding-right: 15px;
  font-size: 12px;
  color: #333;
  text-align: right;
  padding-top: 12px;
  .pos_left{
    position: absolute;
    left: 37px;
    top: 10px;
  }
  .item {
    display: inline-flex;
    align-items: center;
    margin-left: 12px;

    &:last-child {
      margin-left: 26px;
    }

    .icon-circle {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      background: #7E80F8;
      border-radius: 50%;
      vertical-align: middle;

      &.icon-circle-cur {
        background: #27C0BB;
      }
    }
  }

}

.chart-year__container {
  flex: 1;
}

</style>
