<template>
  <div class="main">
    暂无数据
  </div>
</template>

<script>
export default {
  name: 'NoContent',
};
</script>

<style scoped>
    .main{
      text-align: center;
      line-height: 110px;
      color: #777;
      width: 100%;
    }
</style>
