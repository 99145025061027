<template>
  <div class="login-container pull-height">
    <img
      src="@/assets/images/login/sign_bg.png"
      class="login_bg"
    >
    <div class="login_form">
      <div class="zyd_img_title" />
      <div class="content flex">
        <div class="left_content">
          <div><img src="@/assets/images/login/sign_title.png"></div>
          <div><img src="@/assets/images/login/sign_content.png"></div>
        </div>
        <div class="right_content">
          <div class="title">
            Hey！请登录
          </div>
          <userLogin v-if="activeName==='user'" />
        </div>
      </div>
    </div>
    <!--<div class="login-weaper">-->
    <!--<div class="login-left animated fadeInLeft">-->
    <!--<div class="login-info">-->
    <!--<h2 class="login-info-title">{{website.info.title}}</h2>-->
    <!--<ul class="login-info-list">-->
    <!--<li class="login-info-item" v-for="(item,index) in website.info.list" :key="index">-->
    <!--<i class="el-icon-check"></i>-->
    <!--&nbsp;{{item}}-->
    <!--</li>-->
    <!--</ul>-->
    <!--&lt;!&ndash;<el-button type="primary" size="small" class="login-index">首页</el-button>&ndash;&gt;-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="login-border animated fadeInRight">-->
    <!--<div class="login-main">-->
    <!--<h4 class="login-title">-->
    <!--品牌站后台管理系统-->
    <!--</h4>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import userLogin from './userlogin';
import { setStore, getStore } from '@/assets/js/store';
import { getLoginCheck, getGeetestCode } from '../../api/user';
import { validatenull } from '@/assets/js/validate';

export default {
  name: 'Login',
  components: {
    userLogin,
  },
  data() {
    return {
      showCheck: false,
      active: '',
      activeName: 'user',
    };
  },
  watch: {
    $route() {
      const params = this.$route.query;
    },
  },
  created() {
    this.active = getStore({ name: 'tenantId' });
  },
  mounted() {},
  computed: {
    ...mapGetters(['website']),
  },
  props: [],
  methods: {
    handleCommand(command) {
      setStore({ name: 'tenantId', content: command });
    },
  },
};

</script>
<style lang="scss">
.login-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  .login_bg {
    width: 100%;
    position: absolute;
    height: 100%;
  }

  .login_form {
    z-index: 100;
    width: 770px;
    height: 434px;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);
    border-radius: 4px;

    .content {
      margin-top: 85px;
      margin-left: 62px;

      .left_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div {
          margin-bottom: 24px;

          &:nth-child(1) {}
        }
      }

      .right_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 100px;
        margin-top: -50px;
        width: 220px;
        position: relative;

        .el-input__inner {
          padding: 0 29px 0 2px;
        }

        .title {
          font-family: MicrosoftYaHei-Bold;
          line-height: 80px;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #4d5353;
        }
      }
    }

    .zyd_img_title {
      position: absolute;
      left: 20px;
      top: 20px;
      /*background: url("@/assets/images/order/zyd_logo.png") no-repeat;*/
      background-size: 100% 100%;
      width: 92px;
      height: 20px;
    }
  }
}

.login-weaper {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 100%;
  height: 500px;
  margin-top: -220px;
}

.login-container::before {
  z-index: -1024;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*background-image: url("/img/login.png");*/
  background-size: cover;
}

.login-left {
  float: left;
  width: 50%;
  position: relative;
}

.login-info {
  padding-left: 140px;
}

.login-info-title {
  line-height: 90px;
  color: rgb(52, 58, 64);
}

.login-info-item {
  font-size: 14px;
  line-height: 2.5;
  color: rgb(33, 37, 41);
}

.login-border {
  width: 50%;
  float: left;
  box-sizing: border-box;
}

.login-main {
  margin: 0 auto;
  width: 50%;
  box-sizing: border-box;
}

.login-main>h3 {
  margin-bottom: 20px;
}

.login-main>p {
  color: #76838f;
}

.login-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 4px;
}

.login-select {
  input {
    color: #333;
    font-size: 18px;
    font-weight: 400;
    border: none;
    text-align: center;
  }
}

.login-menu {
  width: 100%;
  text-align: center;

  a {
    color: rgb(153, 153, 153);
    font-size: 12px;
    margin: 0px 8px;
  }
}

.login-index {
  margin-top: 40px !important;
  width: 180px;
  height: 48px;
  text-align: center;
  border-radius: 50px !important;
  background: transparent !important;
  color: #333 !important;
  font-size: 16px !important;
  border: 1px solid rgb(152, 22, 244);
}

.login-submit {
  display: block !important;
  margin: 40px auto 10px auto !important;
  width: 200px;
  height: 48px;
  font-size: 14px !important;
  text-align: center;
  border-radius: 50px !important;
  border: 0px;
  box-shadow: rgba(152, 22, 244, 0.19) 0px 5px 10px 2px;
}

.login-form {
  margin: 10px 0;

  i {
    color: #999;
  }

  .el-form-item__content {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 12px;
  }

  .el-input {
    input {
      font-size: 14px;
      padding-bottom: 10px;
      text-indent: 5px;
      background: transparent;
      border: none;
      border-radius: 0;
      color: #333;
      border-bottom: 1px solid rgb(235, 237, 242);
    }
  }
}

.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 10px;
}

.login-code-img {
  margin-top: 2px;
  width: 100px;
  height: 38px;
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 38px;
  text-indent: 5px;
  text-align: center;
}

</style>
