<template>
  <ReportPanel class="panel--notice">
    <template #title>
      产品线销售分析
    </template>
    <!-- <div class="eh-content"> -->
    <!-- <div class="eh-title"> -->
    <!-- <img src="@/assets/images/report/square.png"> -->
    <!-- <span class="ft">产品线销售分析</span> -->
    <!-- </div> -->
    <!-- <div class="eh-chart-content"> -->
    <template #header>
      <div class="legend-year">
        <span class="pos_left">万元</span>
        <span
          v-if="nyearList"
          class="item"
        ><i class="icon-circle" />{{ nyearList[0] }}</span>
        <span
          v-if="nyearList"
          class="item"
        ><i class="icon-circle icon-circle-cur" />{{ nyearList[1] }}</span>
        <!--        <span class="item">同比增长：{{rise}}%</span>-->
      </div>
    </template>
    <div
      id="chartProduct"
      ref="chartProduct"
      class="chart-product"
      :style="{width:eWidth,height:'100%'}"
    />
    <!-- </div> -->
    <!-- </div> -->
  </ReportPanel>
</template>
<script>
import * as echarts from 'echarts';
import { getProductSales } from '../../api/report';
import ReportPanel from './ReportPanel';

export default {
  name: 'ProductSales',
  components: {
    ReportPanel,
  },
  data() {
    return {
      myChart: null,
      xData: [],
      NowVOList: [],
      eWidth: '100%',
      rise: 0,
      statsVOList: [],
      nyearList: [],
    };
  },
  mounted() {
    this.queryProductSales();

    window.addEventListener('resize', () => {
      if (this.myChart) {
        this.myChart.resize();
      }
    });
  },
  methods: {
    createChart() {
      const chartDom = document.getElementById('chartProduct');
      this.myChart = echarts.init(chartDom);
      const option = {
        grid: [{
          left: '68',
          top: '24',
          bottom: '50',
          right: '26',
          // width: '89%',

        }],
        // title: {
        //   text: `同比增长: ${this.rise}%`,
        //   left: '75%',
        //   textStyle: {
        //     fontFamily: 'Microsoft YaHei',
        //     fontSize: 14,
        //     fontStyle: 'normal',
        //     fontWeight: 'normal',
        //   },
        //   padding: 9,
        // },
        color: ['#7E80F8', '#53D5E0'],
        // legend: {
        //   top: '0%',
        //   left: '50%',
        //   padding: 11,
        //   itemHeight: 10,
        //   itemWidth: 10,
        //   data: [{
        //       name: '2020',
        //       icon: 'image:// https://zydmall-brand-site.oss-cn-shenzhen.aliyuncs.com/brands/purple_icon.png',
        //     },
        //     {
        //       name: '2021',
        //       icon: 'image:// https://zydmall-brand-site.oss-cn-shenzhen.aliyuncs.com/brands/green_icon.png',
        //     },
        //   ],
        // },
        tooltip: {
          // position(pt) {
          //   return [pt[0] - 100, pt[1] - 80];
          // },
          formatter(params) {
            return `<p>${params.seriesName}</p>
            <p>${params.marker}<span style="margin-right:10px;">${params.name}</span>${params.data[params.seriesIndex+1]}
            </p>`;
          },
        },
        dataset: {
          source:
            // ['product', '2020', '2021'],
            this.statsVOList,
        },
        xAxis: {
          show: true,
          type: 'category',
          splitLine: {
            show: false,
          }, // 去除网格线
          splitArea: { show: false },
          boundaryGap: true, // 坐标轴两侧留白
          axisLine: {
            show: true, // 隐藏x轴轴线
            lineStyle: {
              color: ' #EAEAEA ',
              width: 2,
              type: 'solid',
            },
          },
          data: this.xData,
          axisTick: {
            show: false, // 隐藏坐标轴的刻度线
            alignWithLabel: true, // 图形于标题居中
            inside: true, // 刻度线是指向内部还是外部
            length: 0, // x轴上指向标题刻度线的长度
          },
          axisLabel: {
            textStyle: {
              color: '#333333', // 坐标轴文字颜色
              fontSize: 12, // 坐标轴文字大小
            },
            interval: 0, // x轴坐标轴刻度标签显示间隔
            formatter(value) {
              if (value.length > 4) {
                const param = value.slice(0, 4);
                const param2 = value.substr(4, value.length);
                return `${param}\n${param2}`;
                // return value.slice(0, 4).join("\n");
              }
              return value;
            },
          },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          splitArea: { show: false },
          nameLocation: 'middle',
          nameTextStyle: {
            // color:'red'
            // padding:[0,40,0,0]
          },
          type: 'value',
          // scale: true,
          min: 0, // 就是这两个 最小值
          // max: 15000, // 最大值
          // splitNumber: 10,
          // 坐标轴刻度标签的相关设置
          axisLabel: {
            color: '#333333',
          },
          axisLine: {
            show: true, // 隐藏x轴轴线
            lineStyle: {
              color: ' #EAEAEA ',
              width: 2,
              type: 'solid',
            },
          },
        },
        series: [{
            type: 'bar',
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 0, 0],
              },
            },
          },
          {
            type: 'bar',
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 0, 0],
              },
            },
          },
        ],
      };
      this.myChart.clear();
      this.myChart.resize({ width: this.eWidth });
      option && this.myChart.setOption(option);
    },
    queryProductSales() {
      getProductSales().then((res) => {
        const NowVO = res.data.productLineSalesNowVO;
        const passVO = res.data.productLineSalesPassVO;
        if (!(NowVO.length && passVO.length)) return;
        const { statsVO } = res.data;
        const xData = [];
        const nyearList = [passVO[0].nyear, NowVO[0].nyear];
        const statsVOList = [
          ['product', `${passVO[0].nyear}`, `${NowVO[0].nyear}`],
        ];
        for (const key in NowVO) {
          xData.push(NowVO[key].sattributeName);
        }
        for (const key in statsVO) {
          // statsVOList.push([statsVO[key].sattributeName, parseInt(((statsVO[key].ntotalAmountPass) / 10000).toFixed(2), 10), parseInt(((statsVO[key].ntotalAmountNow) / 10000).toFixed(2), 10)]);
          statsVOList.push([statsVO[key].sattributeName, parseFloat(((statsVO[key].ntotalAmountPass) / 10000).toFixed(2)), parseFloat(((statsVO[key].ntotalAmountNow) / 10000).toFixed(2))]);
        }
        // ['菲尼克斯DC', 5335, 4245],
        this.nyearList = nyearList;
        this.xData = xData;
        this.statsVOList = statsVOList;
        const chartContainWidth = this.$refs.chartProduct.offsetWidth;
        const setChartWidth = this.xData.length * 80;
        this.eWidth = chartContainWidth > setChartWidth ? `${chartContainWidth}px` : `${setChartWidth}px`;
        this.rise = (res.data.rise * 100).toFixed(2);
        this.createChart();
      })
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
.eh-content {
  position: relative;
  height: 100%;

  .eh-title {
    padding: 12px 0px 0px 15px;

    .ft {
      position: relative;
      font-size: 18px;
      font-weight: bold;
      color: #01B0B0;
      padding-left: 7px;
      /*top: 7px;*/
    }
  }

  .eh-chart-content {
    /*padding: 0px 24px 35px 0px;*/
    width: 100%;
    height: 320px;
    box-sizing: border-box;
  }
}

.chart-product {
  min-width: 100%;
  overflow: hidden;
}

.legend-year {
  /*position: absolute;*/
  position: relative;
  margin: 14px 0 0;
  right: 0;
  padding-right: 15px;
  font-size: 12px;
  color: #333;
  text-align: right;

  .pos_left {
    position: absolute;
    left: 37px;
    top: 10px;
  }

  .item {
    display: inline-flex;
    align-items: center;
    margin-left: 12px;

    &:last-child {
      margin-left: 26px;
    }

    .icon-circle {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      background: #7E80F8;
      border-radius: 50%;
      vertical-align: middle;

      &.icon-circle-cur {
        background: #27C0BB;
      }
    }
  }

}

</style>
