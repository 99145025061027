<template>
  <div class="main-container">
    <div class="row-panel search-panel">
      <el-input
        v-model.trim="searchParams.skey"
        class="search-item"
        style="width: 260px;"
        placeholder="请输入留言关键字"
        size="mini"
        clearable
        @clear="quermesList"
        @keyup.enter.native="searchInit"
      />
      <z-button
        type="primary"
        size="medium"
        class="search-item"
        @click="searchInit"
      >
        查询
      </z-button>
      <div class="search-item">
        <span class="search-label">提交时间</span>
        <el-date-picker
          v-model="searchParams.beginAndEndTime"
          size="mini"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          @change="fetchmessList"
        />
      </div>
    </div>
    <div class="row-panel relative">
      <div
        class="v-content"
      >
        <el-table
          v-loading="table_loading"
          :data="messList"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column
            prop="id"
            label="序号"
            min-width="48"
          >
            <template slot-scope="scope">
              {{ scope.row.serialNum }}
            </template>
          </el-table-column>
          <el-table-column
            prop="realName"
            label="提交人"
            min-width="175"
          >
            <template slot-scope="scope">
              {{ scope.row.realName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            label="留言内容"
            min-width="1034"
          >
            <template slot-scope="scope">
              <div class="nowrap_el">
                {{ scope.row.description }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="提交时间"
            min-width="160"
          >
            <template slot-scope="scope">
              {{ scope.row.updateTime }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            min-width="193"
          >
            <template slot-scope="scope">
              <z-button
                type="text"
                size="small"
                :color="2"
                font-size="small"
                @click="showDialogMessage(scope.row)"
              >
                <span v-if="scope.row.isShowReply===1">查看回复</span>
                <span v-if="scope.row.isShowReply===0">查看并回复</span>
              </z-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          popper-class="paginationComCls"
          style="text-align:center;margin-top:20px"
          layout="sizes, prev, pager, next,total"
          :total="total"
          :page-sizes="[12, 20, 30, 40, 50]"
          :page-size="pageSize"
          :current-page="pageNum"
          @current-change="current_page_change"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <DialogMessage
      v-if="visibleDialog"
      :visible.sync="visibleDialog"
      :dialog-type="dialogType"
      :message-id="messageId"
      @succ="fetchmessList"
    />
  </div>
</template>

<script>
import { messList } from '../../api/messagereply';
import { dealBeginTime, dealEndTime } from '../../../public/js/util';
import DialogMessage from '@/components/message/DialogMessage';
// import DialogMessage from '../../components/message/DialogMessage';

export default {
  name: 'MessageBoard',
  comments: {
    DialogMessage,
  },
  components: { DialogMessage },
  data() {
    return {
      searchParams: {
        skey: '',
        beginAndEndTime: '',
      },
      pageNum: 1,
      pageSize: 12,
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
      loading: false,
      messList: [],
      table_loading: false,
      visibleDialog: false,
      dialogType: null,
      messageId: null,
    };
  },
  computed: {
    beginTime() {
      return this.searchParams.beginAndEndTime ? dealBeginTime(this.searchParams.beginAndEndTime[0]) : '';
    },
    endTime() {
      return this.searchParams.beginAndEndTime ? dealEndTime(this.searchParams.beginAndEndTime[1]) : '';
    },
  },
  mounted() {
    this.quermesList();
  },
  methods: {
    showDialogMessage(item) {
      this.dialogType = item.isShowReply;
      this.messageId = item.id;
      this.visibleDialog = true;
    },
    quermesList() {
      this.table_loading = true;
      const param = {
        startTime: this.beginTime,
        endTime: this.endTime,
        searchKey: this.searchParams.skey,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      messList(param).then((res) => {
        this.messList = res.datas;
        this.total = res.total;
        setTimeout(() => {
          this.table_loading = false;
        }, 500);
      })
    },
    current_page_change(param) {
      this.pageNum = param;
      this.quermesList();
    },
    handleSizeChange(param) {
      this.pageNum = 1;
      this.pageSize = param;
      this.quermesList();
    },
    searchInit() {
      this.pageNum = 1;
      this.quermesList();
    },
    fetchmessList() {
      this.pageNum = 1;
      this.quermesList();
    },
  },
};
</script>

<style lang="scss">
  .v-content{
    .nowrap_el{
      /*white-space: nowrap;*/
      /*text-overflow: ellipsis;*/
      /*overflow: hidden;*/
      /*word-break: break-all;*/
      /*max-width: 1000px;*/
      /*display: block!important;*/
    }
    .el-table__body td.el-table__cell:nth-child(3){
      text-align: left!important;
    }
  }

</style>
