<template>
  <ReportPanel class="panel--subsidiary">
    <template #title>
      子公司销售分析
    </template>
    <template #header>
      <el-tabs
        v-model="sareaBm"
        :stretch="true"
        style="margin-top: 9px;"
        @tab-click="changeTab"
      >
        <el-tab-pane
          v-for="(item,index) in tabStatus"
          :ref="'tab' + item.value"
          :key="index"
          :label="item.text"
          :name="item.value"
        />
      </el-tabs>
    </template>
    <div class="eh-content">
      <div class="pos-ri">
        万元
      </div>
      <div class="eh-content__list">
        <!-- <div class="eh-title"> -->
        <!-- <img src="@/assets/images/report/square.png"> -->
        <!-- <span class="ft">子公司销售分析</span> -->
        <!-- </div> -->
        <!-- <div class="eh-item"> -->
        <div
          v-for="(item, index) in comdata"
          :key="index"
          class="mar-b-16"
        >
          <div class="eh-progress">
            <span class="ft-14">{{ item.scomName }}</span>
            <el-progress
              :show-text="false"
              :stroke-width="20"
              :percentage="item.rise*100"
              class="progress-width"
            />
            <span class="ft-12">{{ item.ntotalAmount?formatNumber((item.ntotalAmount/10000).toFixed(2)):'0.00' }}</span>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </ReportPanel>
</template>
<script>
import { getComSales } from '../../api/report';
import ReportPanel from './ReportPanel';

export default {
  name: 'SalesAnalysis',
  components: {
    ReportPanel,
  },
  data() {
    return {
      tabStatus: [{
        text: '全部',
        value: 'ALL',
      },
      {
        text: '华北东北',
        value: 'W',
      },
      {
        text: '华东华中',
        value: 'N',
      },
      {
        text: '华南西部',
        value: 'O',
      },
      ],
      sareaBm: 'ALL',
      comdata: [],
      progress: 20,
    };
  },
  mounted() {
    this.queryComSales();
  },
  methods: {
    changeTab(tab) {
      const { name } = tab;
      this.sareaBm = name;
      this.queryComSales();
    },
    queryComSales() {
      getComSales(this.sareaBm).then((res) => {
        this.comdata = res.data;
      })
    },
    formatNumber(num) {
      let decimalPart = '';
      num = num.toString();
      if (num.indexOf('.') != -1) {
        decimalPart = `.${num.split('.')[1]}`;
        num = parseInt(num.split('.')[0]);
      }
      const array = num.toString().split('');
      let index = -3;
      while (array.length + index > 0) {
        array.splice(index, 0, ',');
        index -= 4;
      }
      return array.join('') + decimalPart;
    },
  },
};

</script>
<style
  scoped
  lang="scss"
>
.eh-content {
  height: 100%;
  overflow-y: auto;
  position: relative;
  .pos-ri{
    position: absolute;
    right: 38px;
    top: 10px;
    font-size: 12px;
    color: #333;
  }
}
.eh-content__list {
  position: relative;
  /*height: 100%;*/
  box-sizing: border-box;
  margin-top: 28px;
  padding: 0 0 0 4.15%;
  /*padding: 0px 32px 0px 24px;*/
  /*max-height: 240px;*/
  overflow: auto;
  position: relative;

  .eh-title {
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.86);
    border-radius: 10px;
    padding: 12px 15px 0px 15px;

    .ft {
      position: relative;
      font-size: 18px;
      font-weight: bold;
      color: #01B0B0;
      padding-left: 7px;
      /*top: 7px;*/
    }
  }

  /*.eh-item {
    box-sizing: border-box;
    margin-top: 21px;
    padding: 0px 32px 0px 24px;
    max-height: 240px;
    overflow: auto;
    position: relative;*/

  .eh-progress {
    display: flex;
    /*padding: 0 0 0 12px;*/

    .ft-14 {
      flex: none;
      width: 42px;
      margin-right: 10px;
      font-size: 14px;
      color: #666666;
      /*padding-right: 10px;*/
      line-height: 20px;
      /*width: 50px;*/
      text-align: center;
    }

    .ft-12 {
      flex: none;
      width: 68px;
      margin-left: 14px;
      font-size: 12px;
      color: #19B0B0;
      line-height: 20px;
      /*position: absolute;*/
      /*right: 20px;*/
    }

    .progress-width {
      /*width: 367px;*/
      flex: 1;
    }
  }

  .mar-b-16 {
    margin-bottom: 16px;
  }

  /*}*/
}

.panel--subsidiary {
  ::v-deep .panel__bd {
    &::after {
      position:absolute;
      content: '';
      width: 100%;
      height: 0;
      left: 0;
      bottom: 0;
      box-shadow: 0 0 44px 44px #fff;
    }
  }
}

</style>
<style lang="scss">
.eh-title {
  .el-tabs__nav-wrap {
    box-shadow: none !important;
  }

  .el-tabs__nav-wrap::after {
    display: none !important;
  }

  .el-tabs__item {
    padding: 0 32px !important;
  }
}

.eh-content__list {
  .el-progress-bar__inner::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 2px solid #28CCCC;
    border-radius: 50%;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
  }

  .el-progress-bar__inner {
    min-width: 20px;
    background: linear-gradient(90deg, #7E80F8 0%, #53D5E0 99%);
  }

  .el-progress-bar__outer {
    background-color: #ffffff;
  }

  .el-progress-bar__innerText {
    color: black !important;
  }
}
</style>
