<template>
  <div class="panel">
    <div class="panel__hd">
      <div class="panel-title">
        <img
          class="icon"
          src="@/assets/images/report/square.png"
        >
        <slot name="title" />
      </div>
      <slot name="header" />
    </div>
    <div class="panel__bd">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReportPanel',
};

</script>
<style
  lang="scss"
  scoped
>
.panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  /*height: 318px;*/
  height: 100%;
  /*margin-right: 28px;*/
  box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);
  border-radius: 10px;
  background: #fff;
  overflow: hidden;

  .panel__hd {
    flex: none;
    /*height: 46px;*/

    .panel-title {
      position: relative;
      padding: 12px 15px 0;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: bold;
      color: #01b0b0;
      background: #fff;

      .icon {
        width: 1.8vw;
        max-width: 35px;
        margin-right: 6px;
      }
    }

    @media screen and (max-width:1600px) {
      .panel-title {
        font-size: 16px;
      }
    }

    @media screen and (max-width:1400px) {
      .panel-title {
        font-size: 14px;
      }
    }

    ::v-deep .el-tabs {
      margin-top: 0 !important;
      padding-top: 8px !important;

      box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.86);

      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        line-height: 30px;
      }

      .el-tabs__header {
        /*margin: 0 40px 0 30px !important;*/
        margin: 0 20px !important;

        .el-tabs__nav-wrap {
          &::after {
            display: none;
          }
        }
      }

      .el-tabs__item {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }
    }
  }

  .panel__bd {
    position: relative;
    flex: 1;
    margin: 2px;
    /*margin: 0 4px 38px;*/
    /*margin: 0 4px 6%;*/
    /*padding: 0 5px;*/
    overflow: auto;
  }
}

</style>
