<template>
  <div class="reportCenter">
    <div class="reportCenter__colLeft">
      <AnnualSales />
      <product-sales style="margin-top: 1.5625vw" />
    </div>
    <div class="reportCenter__colcenter">
      <deal-num />
    </div>
    <div class="reportCenter__colRight">
      <sales-analysis />
      <sku-data style="margin-top: 1.5625vw" />
    </div>
  </div>
  <!--    <div style="width: 530px;height: 373px;box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);border-radius: 10px;">-->
  <!--      <product-sales></product-sales>-->
  <!--      <AnnualSales></AnnualSales>-->
  <!--      <sales-analysis></sales-analysis>-->
  <!--      <sku-data></sku-data>-->
  <!--    </div>-->
</template>
<script>
import ProductSales from './ProductSales';
import AnnualSales from './AnnualSales';
import SalesAnalysis from './SalesAnalysis';
import SkuData from './SkuData';
import DealNum from './DealNum';

export default {
  name: 'ReportCenter',
  components: {
    ProductSales,
    AnnualSales,
    SalesAnalysis,
    SkuData,
    DealNum,
  },
};

</script>
<style
  lang="scss"
  scoped
>
.reportCenter {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 63px);
  padding-top: 2.44% !important;
  padding-bottom: 1.62% !important;

  .reportCenter__colLeft {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    /*max-width: 530px;*/
    width: 32%;
    /*min-width: 330px;*/
    border-radius: 5px;
    z-index: 1;
  }

  .reportCenter__colcenter {
    flex: none;
    /*max-width: 600px;*/
    width: 36%;
    display: flex;
    flex-direction: column;
    /*border-radius: 5px;*/
  }

  .reportCenter__colRight {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    /*max-width: 530px;*/
    width: 32%;
    border-radius: 5px;
    z-index: 1;
    .panel {
      height: 0;
    }
  }

}

</style>
