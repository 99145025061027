<template>
  <div>
    <div class="main-container">
      <div class="row-panel search-panel">
        <el-input
          v-model.trim="searchParams.skey"
          class="search-item"
          style="width: 260px;"
          placeholder="请输入"
          size="mini"
          @keyup.enter.native="searchInit"
        />
        <z-button
          type="primary"
          size="medium"
          class="search-item"
          @click="searchInit"
        >
          查询
        </z-button>
        <div class="search-item">
          <span class="search-label">商务人员</span>
          <el-select
            v-model.number="searchParams.nsaleman_id"
            filterable
            placeholder="全部"
            size="mini"
            style="width:120px"
            @change="bussiChange"
          >
            <el-option
              label="全部"
              value=""
            />
            <el-option
              v-for="(item,index) in swOptionList"
              :label="item.values"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="search-item">
          <span class="search-label">下单时间</span>
          <el-date-picker
            v-model="createOrderDate"
            size="mini"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            @change="fetchOrderList"
          />
        </div>
      </div>
      <div class="row-panel">
        <el-tabs
          v-model="searchParams.sods_status"
          @tab-click="changeTab"
        >
          <el-tab-pane
            v-for="(item,index) in tabStatus"
            :ref="'tab' + item.value"
            :label="item.text"
            :name="item.value"
            :disabled="loading"
          />
        </el-tabs>
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column
            prop="date"
            label="退仓单状态"
            min-width="140"
          >
            <template slot-scope="scope">
              {{ scope.row.sods_status }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="合同审核"
            min-width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.ncontract_state }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="退仓单号"
            min-width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.sror_id }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="客户名称"
            min-width="220"
          >
            <template slot-scope="scope">
              {{ scope.row.sreal_name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="客户账号"
            min-width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.suser_name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="退仓单金额"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.ntotal_amount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="电子章"
            min-width="80"
          >
            <template slot-scope="scope">
              {{ scope.row.sexist_signature }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="商务"
            min-width="80"
          >
            <template slot-scope="scope">
              {{ scope.row.ssaleman_name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="下单时间"
            min-width="140"
          >
            <template slot-scope="scope">
              {{ scope.row.dcreate_time }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="更新时间"
            min-width="140"
          >
            <template slot-scope="scope">
              {{ scope.row.dmodify_time }}
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="操作"
            fixed="right"
            min-width="120"
          >
            <template slot-scope="scope">
              <z-button
                type="text"
                size="small"
                :color="2"
                font-size="small"
                @click="visibleDialog=true"
              >
                弹出窗口
              </z-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          popper-class="paginationComCls"
          style="text-align:center;margin-top:20px"
          layout="sizes, prev, pager, next,total"
          :total="total"
          :page-sizes="[12, 20, 30, 40, 50]"
          :page-size="pageParams.page_size"
          :current-page="pageParams.page_index"
          @current-change="current_page_change"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <!-- 弹窗业务组件 -->
    <DemoDialog
      v-if="visibleDialog"
      :visible.sync="visibleDialog"
    />
    <!-- 弹窗业务组件 end -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  getSwList,
  getCancelCloudStorageList,
} from '@/api/order';
import DemoDialog from './DemoDialog';

export default {
  name: 'DemoPage',
  components: {
    DemoDialog,
  },
  data() {
    return {
      visibleDialog: false,
      // 查看PDF
      orderId: 0,
      loading: false,
      swOptionList: [],
      order_num: {},
      tableData: [],
      activeName: 'ALL',
      total: 0,
      createOrderDate: [],
      // 分页参数
      pageParams: {
        page_index: 1,
        page_size: 12,

      },
      // 搜索栏参数
      searchParams: {
        skey: '',
        dcreate_begin_date: '',
        dcreate_end_date: '',
        sods_status: 'ALL',
        nsaleman_id: '',
        sort: '',
        scom_bm: '',
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
      tabStatus: [{
        text: '处理中',
        value: 'WAITSALES',
      },
      {
        text: '已处理',
        value: 'WAITMANAGER',
      },
      {
        text: '已完成',
        value: 'FINISH',
      },
      {
        text: '已作废',
        value: 'DEAD',
      },
      {
        text: '全部',
        value: 'ALL',
      },
      ],
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    queryParams() {
      return { ...this.searchParams, ...this.pageParams };
    },
  },
  watch: {
    activeName(newName, oldName) {
      this.pageParams.page_index = 1;
      this.fetchOrderList();
    },
    createOrderDate(value, oldValue) {
      if (value) {
        [this.searchParams.dcreate_begin_date, this.searchParams.dcreate_end_date] = value;
      }
    },
  },
  mounted() {
    this.searchParams.scom_bm = this.userInfo.scomBm;
    this.fetchBusinessList().then(() => {
      this.fetchOrderList();
    });
  },
  methods: {
    searchInit() {
      this.pageParams.page_index = 1;
      this.order = '';
      this.fetchOrderList();
    },
    bussiChange() {
      this.pageParams.page_index = 1;
      this.fetchOrderList();
    },
    fetchBusinessList() {
      return new Promise((resolve, reject) => {
        getSwList(this.searchParams.scom_bm).then((response) => {
          if (response) {
            const idArr = [];
            for (const key in response) {
              idArr.push(key);
              this.swOptionList.push({
                id: parseInt(key),
                values: response[key],
              });
            }
            const currItem = this.swOptionList.filter((item) => this.userInfo.id === item.id);
            if (currItem.length) this.searchParams.nsaleman_id = currItem[0].id;
            resolve();
          } else {
            reject();
          }
        }).catch((e) => {
          reject(e);
        });
      });
    },
    fetchOrderList() {
      getCancelCloudStorageList(this.queryParams).then((response) => {
        this.loading = false;
        const { list, pager, info } = response;
        if (response) {
          this.tableData = list;
          this.total = pager.total;
          this.order_num = info;
        }
      });
    },
    current_page_change(param) {
      this.pageParams.page_index = param;
      this.fetchOrderList();
    },
    handleSizeChange(param) {
      this.pageParams.page_index = 1;
      this.pageParams.page_size = param;
      this.fetchOrderList();
    },
    changeTab() {
      this.fetchOrderList();
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
</style>
