<template>
  <div class="panel">
    <div class="panel__bd">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'DataPanel',
};

</script>
<style
  lang="scss"
  scoped
>
.panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);
  border-radius: 10px;
  background: #fff;
  .panel__bd {
    flex: 1;
    height: 373px;
    overflow: auto;
  }
}
</style>
