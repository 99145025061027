<template>
  <div>
    <transition name="slide-notice">
      <div
        v-if="visible&&!isEmpty"
        class="notification-msgManage"
      >
        <div class="notification-hd">
          消息通知<i
            class="close el-icon el-dialog__close el-icon-close"
            @click="close"
          />
        </div>
        <div class="notification-content">
          <!-- type==MALL的消息 -->
          <div v-if="noticeTypeMall&&isCurPageIndex">
            <p
              v-if="noticeTypeMall.askNum!=0"
              class="item"
              @click="linkByName('订单','1')"
            >
              询价待处理（<em>{{ noticeTypeMall.askNum }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.orderNum!=0"
              class="item"
              @click="linkByName('订单','3')"
            >
              订单待处理（<em>{{ noticeTypeMall.orderNum }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.prepareOrderNum!=0"
              class="item"
              @click="linkByName('备货单','1')"
            >
              备货单待处理（<em>{{ noticeTypeMall.prepareOrderNum }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.electronicContract!=0"
              class="item"
              @click="linkByName('合同审核','AUDIT')"
            >
              电子合同待审核（<em>{{ noticeTypeMall.electronicContract }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.paperContract!=0"
              class="item"
              @click="linkByName('订单','4')"
            >
              纸质合同待审核（<em>{{ noticeTypeMall.paperContract }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.electronicSeal!=0"
              class="item"
              @click="linkByName('电子签章','AUDIT')"
            >
              电子签章待审核（<em>{{ noticeTypeMall.electronicSeal }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.verifiedMember!=0"
              class="item"
              @click="linkByName('客户管理','12')"
            >
              会员认证待审核（<em>{{ noticeTypeMall.verifiedMember }}</em>）
            </p>
            <p
              v-if="!!noticeTypeMall.refundApply"
              class="item"
              @click="linkByName('退款审核','1')"
            >
              退款申请待处理（<em>{{ noticeTypeMall.refundApply }}</em>）
            </p>
            <p
              v-if="!!noticeTypeMall.refundProgress"
              class="item"
              @click="fnUpdateRefundProgress()"
            >
              退款进度更新（<em>{{ noticeTypeMall.refundProgress }}</em>）
            </p>
            <p
              v-if="!!noticeTypeMall.refundDeal"
              class="item"
              @click="linkByName('交易明细','2')"
            >
              退款待处理（<em>{{ noticeTypeMall.refundDeal }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.retPrepareOrder!=0"
              class="item"
              @click="linkByName('退仓单','1')"
            >
              退仓单待处理（<em>{{ noticeTypeMall.retPrepareOrder }}</em>）
            </p>
            <p
              v-if="noticeTypeMall.retPrepareContractOrder!=0"
              class="item"
              @click="linkByName('合同审核','AUDIT')"
            >
              退仓合同待审核（<em>{{ noticeTypeMall.retPrepareContractOrder }}</em>）
            </p>
          </div>
          <!-- type==MALL的消息 end -->
          <!-- type==NOTICE的消息 -->
          <p
            v-for="item in noticeTypeNotice"
            class="item"
            @click="linkForNotice(item.url,item.type)"
          >
            <span class="content">{{ item.msg }}</span><span class="time">{{ item.time }}</span>
          </p>
          <!-- type==NOTICE的消息 end -->
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { getNotice, updateRefundProgress } from '../../api/user';

export default {
  name: 'Notification',
  // 引入reload方法
  inject: ['reload'],
  props: {
    // 用户id
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      baseUrl: process.env.NODE_ENV === 'development' ? '' : '/service',
      ws: null,
      wsUrl: '',
      // 连接实例
      stompClient: null,
      // 消息通知组件实例
      myNotification: null,
      // type==MALL的消息对象
      noticeTypeMall: null,
      // type==NOTICE的消息对象
      noticeTypeNotice: [],
      // 连接状态
      isConnect: false,
      // 是否手动断开
      selfClose: false,
      num: 1,
    };
  },
  computed: {
    // 是否让消息通知隐藏
    visible: {
      get() {
        return this.$store.state.common.isNotificationVisible;
      },
      set() {

      },
    },
    // 是否消息内容为空
    isEmpty: {
      get() {
        // &&(在首页并且type为MALL的消息不为空)
        // type类型为NOTICE的消息是为空
        const isEmptybyNotice = !this.noticeTypeNotice.length;
        // type为MALL的消息是否为空（只有首页需要判断该消息类型)
        const isEmptybyMall = this.isCurPageIndex ? !this.noticeTypeMall : true;
        return isEmptybyNotice && isEmptybyMall;
      },
    },
    isCurPageIndex() {
      return this.$route.path.indexOf('wel') >= 0;
    },
  },
  watch: {
    // 首次添加tab标签的时候，$keepAlive都是false（未去查明真相），临时改为true，否则首次切换到别的标签无法缓存
    $route: {
      handler(to, from) {
        // 如果是首页
        if (this.isCurPageIndex) {
          this.fnGetMallNotice();
          // if(this.noticeTypeMall){
          this.show();
          // }
          // this.connect()
        } else {
          // this.noticeTypeMall = null

          // this.disconnect()
          if (this.myNotification) {
            this.myNotification.close();
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.fnGetMallNotice();
    this.connect();
  },
  methods: {
    linkByName(name, tab) {
      this.$router.push({
        // path: `${url}`,
        name,
        params: {
          tab,
        },
      });
    },
    linkForNotice(url, type) {
      // 如果要跳转的是当前页面，需要处理刷新问题
      if (url === this.$route.path) {
        this.reload(url);
      } else {
        // 如果要跳转的不是当前页面，直接跳转
        this.$router.push({
          path: url,
        });
      }

      this.noticeTypeNotice = this.noticeTypeNotice.filter((item) => item.type != type);
    },
    fnGetMallNotice() {
      const _this = this;
      getNotice().then((response) => {
        if (response) {
          // this.$store.commit('SET_NOTIFICATION_VISIBLE', false)
          let total = 0;
          for (const item in response) {
            total += parseInt(response[item]);
          }
          _this.$emit('update:noticeNumForMall', total);
          _this.$store.commit('UPDATE_NOTIFICATION_NUM', total);
          // 有数据才显示弹框
          if (total) {
            _this.noticeTypeMall = response;
            this.show();
          }
        }
      });
    },
    // 退款进度更新
    fnUpdateRefundProgress() {
      const _this = this;
      this.linkByName('订单', '6');
      updateRefundProgress(_this.id).then((res) => {

      });
    },
    PrefixInteger(number) {
      return ((Array(2).join(0) + parseInt(number)).slice(-2)).toString();
    },
    formatDate() {
      const date = new Date();
      return `${this.PrefixInteger(date.getHours())}:${this.PrefixInteger(date.getMinutes())}:${this.PrefixInteger(date.getSeconds())}`;
    },
    handleNoticeCommon(data) {
      const _this = this;
      const date = new Date();
      this.$set(data, 'time', this.formatDate());
      this.noticeTypeNotice.unshift(data);
      this.show();
    },
    // 显示消息弹框
    show() {
      const _this = this;
      // this.$store.commit('SET_NOTIFICATION_VISIBLE', false)
      setTimeout(() => {
        _this.$store.commit('SET_NOTIFICATION_VISIBLE', true);
      }, 10);
    },
    close() {
      this.noticeTypeNotice = [];
      this.$store.commit('SET_NOTIFICATION_VISIBLE', false);
    },
    // 设置连接状态
    setConnect(status) {
      this.isConnect = status;
    },
    // 重连
    reConnect() {
      if (this.isConnect) { return; }
      this.connect(this.wsUrl);
    },
    connect() {
      const _this = this;
      // const wsUrl = `/param/websocket?id=${this.id}`
      // websocket = new WebSocket(wsUrl);
      // 开启socket连接
      this.wsUrl = `${this.baseUrl}/websocket/websocket?id=ODS-${this.id}`;
      this.ws = new SockJS(this.wsUrl);
      this.stompClient = Stomp.over(this.ws);
      this.stompClient.connect({}, (frame) => {
        this.setConnect(true);
        // _this.stompClient.subscribe('/topic/public', function(res) {
        //   const { data } = JSON.parse(res.body)
        //   _this.fnGetMallNotice()
        // });
        _this.stompClient.subscribe('/user/topic/user', (res) => {
          const { data, type } = JSON.parse(res.body);
          if (type === 'MALL') {
            if (_this.isCurPageIndex) {
              _this.$store.commit('SET_NOTIFICATION_VISIBLE', false);
            }
            _this.fnGetMallNotice();
          } else if (type === 'NOTICE') {
            _this.$store.commit('SET_NOTIFICATION_VISIBLE', false);
            _this.handleNoticeCommon(JSON.parse(data.scontent));
          }
        });
      }, (error) => {
        this.setConnect(false);
        this.connectErr.apply(this, [stompClient, error]);
      });

      this.ws.onclose = (e) => {
        this.isConnect = false;
        if (!this.selfClose) {
          // this.reConnect();
        }
      };
    },
    // 手动断开连接
    disconnect() {
      if (this.stompClient != null && this.isConnect) {
        this.stompClient.disconnect();
        this.isConnect = false;
        this.selfClose = true;
        this.ws = null;
        this.stompClient = null;
      }
    },

  },
};

</script>
<style lang="scss">
.notification-msgManage {
  position: fixed;
  width: 300px;
  right: 0;
  bottom: 0;
  margin: 20px;
  padding: 0;
  border: none;
  box-shadow: 0px 6px 20px 0px rgba(234, 234, 234, 0.86);
  border-radius: 4px;
  background: #fff;
  z-index: 9999;

  .notification-hd {
    height: 36px;
    line-height: 36px;
    padding: 0 0 0 12px;
    color: #fff;
    font-weight: normal;
    border-radius: 4px 4px 0 0;
    font-size: 16px;
    /*background:linear-gradient(-22deg,rgba(39,203,203,1) 0%,rgba(115,225,225,1) 100%);*/
    background: rgba(39, 203, 203, 1) url('../../assets/images/bg-notification-title.png') no-repeat center center;
    background-size: 100% 100%;

    &:before {
      position: relative;
      display: inline-block;
      content: '';
      width: 16px;
      height: 18px;
      margin-right: 8px;
      vertical-align: -4px;
      background: url('../../assets/images/icon-notice.png') no-repeat center center;

    }

    .close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      text-align: center;
      transition: linear .2s;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  .notification-content {
    max-height: 200px;
    margin: 8px 8px;
    padding: 0 12px;
    background: #fff;
    overflow: auto;

    .item {
      cursor: pointer;
      line-height: 1.4em;
      color: #333;
      font-size: 14px;
      padding: 4px 0;

      em {
        color: #19AFAF;
      }

      .content {
        margin-right: 10px;
        word-break: break-all;
      }

      .time {
        display: inline-block;
        color: #666;
      }

      &:hover .time,
      &:hover {
        color: #19AFAF;
      }

    }
  }
}

.slide-notice-enter-active {
  transition: linear .2s;
}

.slide-notice-enter {
  transform: translate3d(200px, 0, 0);
  opacity: 0;
}

.slide-notice-leave-to {
  opacity: 0;
}

</style>
