import request from '@/api/axios';
// var apiweb;
// if(location.origin === 'https://ods.zydmall.com'){
//   apiweb = "https://www.zydmall.com/service/"
// }else if(location.origin === 'http://10.10.31.75:8088'){
//   apiweb = "http://10.10.31.75:8088/service/"
// }else{
//   apiweb = 'http://10.10.31.61:8088/service/'
// }
// 获取订单列表
export function orderList(param) {
  return request({
    url: `${`/order/order/l_orderList?orderTabStatus=${param.activeName}&pageNum=${param.pageNum}&pageSize=${param.pageSize}`
      + `&companyBms=${param.comBm}`
      + `&schKeyword=${param.schKeyword}`
      + `&businessManId=${param.region}`
      + `&orderType=${param.orderResource}`
      + `&addDate=${param.beginTime}`
      + `&endDate=${param.endTime}`}${
      param.sort ? `&sort=${param.sort}` : ''
    }${param.order ? `&order=${param.order}` : ''}`,
    method: 'get',
  });
}

// 获取商务列表
export function getSwList(comBm) {
  return request({
    url: `/user/user/l_fndUserByComBm?comBms=${comBm}`,
    method: 'get',
    noNProgress: true,
  });
}

// 导入erp
export function importErp(param) {
  return request({
    url: '/order/order/l_importERPNew?'
      + `orderId=${param.id}`
      + `&ifsAccount=${param.ifsAccount}`
      + `&ifsCustBm=${param.ifsCustBm}`
      + `&checkFlag=${param.checkFlag}`
      + `&stockId=${param.stockId}`
      + `&compangBm=${param.compangBm}`,
    method: 'get',
  });
}

// 作废订单或询价单
export function cancelOrder(param) {
  return request({
    url: `/order/order/l_orderDead?orderId=${param.orderId}&note=${param.note}`,
    method: 'get',
  });
}

// 根据订单编号查询合同列表
export function loadContractList(param) {
  return request({
    url: `/order/contract/l_getContractList?pageNum=0&pageSize=0&orderNumber=${param.orderNumber}`,
    method: 'get',
  });
}
export function loadorderContractList(param) {
  return request({
    url: `/order/contract/l_getContractList?pageNum=0&pageSize=0&orderNumber=${param}`,
    method: 'get',
  });
}

// 导出订单列表(下载)
export function exportOrder(param) {
  return request({
    url: `/order/order/l_exportOrderListDowload?flag=${param}`,
    method: 'get',
  });
}

// 导出订单列表(触发)
export function getExportUrl(param) {
  return request({
    url: `/order/order/l_exportOrderList?orderTabStatus=${param.activeName}&pageNum=${param.pageNum}&pageSize=${param.pageSize}`
      + `&companyBms=${param.comBm}`
      + `&schKeyword=${param.schKeyword}`
      + `&businessName=${param.region}`
      + `&orderType=${param.orderResource}`
      + `&addDate=${param.beginTime}`
      + `&endDate=${param.endTime}`,
    method: 'get',
  });
}

// 电子签章审核列表
export function getAuthInfoList(param) {
  return request({
    url: '/order/auth/l_getAuthInfoList?'
        + `pageNum=${param.pageNum}`
        + `&pageSize=${param.pageSize}`
        + `&keyword=${param.keyword}`
        + `&state=${param.state}`,
    method: 'get',
  });
}

// 导出excel
export function getexportExcel(orderId) {
  request({
    url: `/order/order/l_exportExcel?orderId=${orderId}`,
    method: 'get',
    responseType: 'blob',
  }).then((response) => {
    console.log(response.data);
    const blob = new Blob([response.data], {
      // type: 'application/vnd.ms-excel'
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    // console.log(blob)
    // console.log("response:",response)
    // const fileName = response.headers["content-disposition"].split(';')[1].split('filename=')[1];
    const fileName = response.headers['content-disposition'].split('filename=')[1];
    // let fileName = Date.parse(new Date()) + '.xlsx'

    console.log(decodeURI(fileName));
    if (window.navigator.msSaveOrOpenBlob) {
      // console.log(2)
      navigator.msSaveBlob(blob, decodeURI(fileName));
    } else {
      console.log(3);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURI(fileName);
      link.click();
      // 释放内存
      window.URL.revokeObjectURL(link.href);
    }
  });
}
// 导出pdf
export function getexportpdf(orderId) {
  request({
    url: `/order/order/l_exportPDF?orderId=${orderId}`,
    method: 'get',
    responseType: 'blob',
  }).then((response) => {
    console.log(response.data);
    const blob = new Blob([response.data], {
      // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      type: 'application/pdf',
    });
    console.log(blob);
    // let fileName = Date.parse(new Date()) + '.pdf'
    // const fileName = response.headers["content-disposition"].split(';')[1].split('filename=')[1];
    const fileName = response.headers['content-disposition'].split('filename=')[1];
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, decodeURI(fileName));
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURI(fileName);
      link.click();
      // 释放内存
      window.URL.revokeObjectURL(link.href);
    }
  });
}
export function contractlist(orderNumber, contractState) {
  return request({
    url: `/order/contract/l_getContractList?pageNum=0&pageSize=0&orderNumber=${orderNumber}&contractState=${contractState || ' '}`,
    method: 'get',
  });
}
export function checkInventory(nerpProId) {
  return request({
    url: `/order/order/l_getStorageList?nerpProId=${nerpProId}`,
    method: 'post',
    noNProgress: true,
  });
}
// 查看合同详情
export function querypdf(pid) {
  return request({
    url: `/order/contract/l_getContractInfo?contractId=${pid}`,
    method: 'get',
  });
}

// 合同审核列表
export function getContractList(param) {
  return request({
    url: '/order/contract/l_query',
    method: 'post',
    data: param,

  });
}
// 线下合同审核列表
export function getlineaudit(param) {
  return request({
    url: '/order/order/l_underAudit',
    method: 'post',
    data: param,

  });
}

// 备货单合同审核
export function cloudContractCheck(param) {
  return request({
    url: `/order/contract/l_prepareAuditContract?orderNumber=${param.orderNumber}&comBm=${param.comBm}&auditState=${param.auditState}&auditReason=${param.auditReason}`,
    method: 'post',
    data: param,

  });
}
// 退仓单合同审核
export function cloudtuiContractCheck(param) {
  return request({
    url: `/order/contract/l_prepareReturnAuditContract?orderNumber=${param.orderNumber}&comBm=${param.comBm}&auditState=${param.auditState}&auditReason=${param.auditReason}`,
    method: 'post',
    data: param,

  });
}
// 合同审核查看商务人员
export function fndBusManList(combm) {
  return request({
    url: `/user/person/l_fndBusManListByCom?comBm=${combm}`,
    method: 'get',
  });
}
// 合同审核查看取消原因
export function fndcanelmessage(ordernumber) {
  return request({
    url: `/order/contract/l_orderCancelMessage?orderNumber=${ordernumber}`,
    method: 'post',
  });
}

// 获取电子签章详情
export function selectElecByPkid(pkid) {
  return request({
    url: `/order/auth/l_selectByPkid/l_${pkid}`,
    method: 'get',
  });
}

// 获取合同完整路径
export function getTemporaryUrl(url) {
  return request({
    url: `/order/signature/l_getTemporaryUrl?url=${url}`,
    method: 'get',
  });
}

// 撤销电子签章
export function cancelElec(param) {
  return request({
    url: `/order/auth/l_cancelSeal?userId=${param.userId}&failureReason=${param.reason}`,
    method: 'get',
  });
}

// 审核企业认证
export function auditCertification(param) {
  return request({
    url: `/order/auth/l_auditCertification?userId=${param.userId}&sauditState=${param.status}&rejectReason=${param.rejectReason}${
      param.startTime ? `&startTime=${param.startTime}` : ''
    }${param.endTime ? `&endTime=${param.endTime}` : ''}`,
    method: 'get',
  });
}

// 根据货期字符串获取货期json
export function deliveryStrToJson(deliveryStr) {
  return request({
    url: `/order/order/l_deliveryStrToJson?deliveryStr=${deliveryStr}`,
    method: 'get',
  });
}

// 根据订单编号查询取消申请的原因
export function canelreson(ordernumber) {
  return request({
    url: `/order/order/l_cancelOrderInfo?orderNumber=${ordernumber}`,
    method: 'get',
  });
}

// 订单导入结果
export function importERPResult(id) {
  return request({
    url: `/order/order/l_importERPResult?orderId=${id}`,
    method: 'get',
  });
}

// 重新获取货期
export function getGetDeliveryList(body) {
  return request({
    url: '/order/order/l_getGetDeliveryList',
    method: 'post',
    data: body,
  });
}
// 取消纸质合同
export function getCancelUpload(id) {
  return request({
    url: `/order/order/v2/l_cancelUpload?contractId=${id}`,
    method: 'post',
  });
}
// 申请退款
export function applyRefund(data) {
  return request({
    url: '/order/order/l_orderApplyRefund',
    method: 'post',
    data,
  });
}

// 根据订单号查询退款信息
export function applyInfoByorderNumber(param) {
  return request({
    url: `/order/order/l_findOrderRefundProgress?orderNumber=${param}`,
    method: 'get',
  });
}
// 分享订单 作废
export function shareOrder(param) {
  return request({
    url: `/order/order/l_getWxacodeunlimit?orderId=${param}`,
    method: 'get',
  });
}

// 分享订单
export function queryshareOrder(param) {
  return request({
    url: `/order/order/l_share?orderId=${param}`,
    method: 'get',
  });
}
// 查询备货单列表
export function getcloudStorageList(param) {
  return request({
    url: '/cw/admin/prepare_order/l_search',
    method: 'post',
    data: param,
  });
}

// 作废备货单
export function deadcloudStorage(param) {
  return request({
    url: '/cw/admin/prepare_order/l_dead',
    method: 'post',
    data: param,
  });
}

// 备货单详情
export function cloudStorageInfo(param) {
  return request({
    url: '/cw/admin/prepare_order/l_detail',
    method: 'post',
    data: param,
  });
}

// 保存备货单
export function saveCloudStorageInfo(param) {
  return request({
    url: '/cw/admin/prepare_order/l_save',
    method: 'post',
    data: param,
  });
}

// 备货单日志
export function cloudStorageLog(param) {
  return request({
    url: '/cw/admin/prepare_order_log/l_info',
    method: 'post',
    data: param,
  });
}

// 备货单提交审核
export function submitOrder(param) {
  return request({
    url: '/cw//admin/prepare_order/l_audit',
    method: 'post',
    data: param,
  });
}

// 获取云仓实时库存
export function cloudStorageCurrent(param) {
  return request({
    url: '/cw/admin/prepare_order/l_erpStore_current',
    method: 'post',
    data: param,
  });
}

// 刷新产品可占用数
export function updateErpStore(param) {
  return request({
    url: '/cw/admin/prepare_order/l_erpStore',
    method: 'post',
    data: param,
  });
}

// 导出备货单明细
export function cloudStorageExport(param, callback) {
  request({
    url: '/cw/admin/prepare_order/l_export',
    method: 'post',
    data: param,
    responseType: 'blob',
  }).then((response) => {
    const blob = new Blob([response.data], {
      // type: 'application/vnd.ms-excel'
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const fileName = response.headers['content-disposition'].split('filename=')[1];

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, decodeURI(fileName));
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURI(fileName);
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
    callback();
  }).catch(() => {
    callback();
  });
}

// 查询退仓单列表
export function getCancelCloudStorageList(param) {
  return request({
    url: '/cw/admin/prepare_return_order/l_search',
    method: 'post',
    data: param,
  });
}

// 作废退仓单
export function deadCancelCloudStorage(param) {
  return request({
    url: '/cw/admin/prepare_return_order/l_dead',
    method: 'post',
    data: param,
  });
}

// 退仓单详情
export function cancelCloudStorageInfo(param) {
  return request({
    url: '/cw/admin/prepare_return_order/l_detail',
    method: 'post',
    data: param,
  });
}

// 保存退仓单
export function saveCancelCloudStorage(param) {
  return request({
    url: '/cw/admin/prepare_return_order/l_save',
    method: 'post',
    data: param,
  });
}

// 获取退仓单日志
export function cancelCloudStorageLog(param) {
  return request({
    url: '/cw/admin/prepare_return_order_log/l_info',
    method: 'post',
    data: param,
  });
}
// 判断合同是否有b类c类产品
export function isBandC(param) {
  return request({
    url: '/cw/feign/prepare_order/l_checkHasBCProduct',
    method: 'post',
    data: param,
  });
}

// 导出备货单明细
export function cancelCloudStorageExport(param, callback) {
  request({
    url: '/cw/admin/prepare_return_order/l_export',
    method: 'post',
    data: param,
    responseType: 'blob',
  }).then((response) => {
    const blob = new Blob([response.data], {
      // type: 'application/vnd.ms-excel'
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const fileName = response.headers['content-disposition'].split('filename=')[1];

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, decodeURI(fileName));
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURI(fileName);
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
    callback();
  }).catch(() => {
    callback();
  });
}

// 收货地址-编辑收货地址
export function updateUserAddress(param) {
  return request({
    url: 'cust/cust/address/l_update',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
// 收货地址-新增收货地址
export function addUserAddress(param) {
  return request({
    // cust/cust/l_addUserAddress
    url: 'cust/cust/address/l_add',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
// 根据等级及父ID获取地址信息
export function getAddressInfobyLevelAndParentId(param) {
  return request({
    url: `/cust/cust/l_getAddressInfobyLevelAndParentId?scityLevel=${param.scityLevel}&nparentId=${param.nparentId}`,
    method: 'get',
    noNProgress: true,
  });
}
// 根据主键获取用户收货地址
export function getUserAddressById(param) {
  return request({
    url: `cust/cust/address/l_getById?pkid=${param.pkid}&userId=${param.userId}`,
    method: 'get',
    noNProgress: true,
  });
}
// 获取收货地址（合并地址字段并默认列第一位）
export function userAddressListDefault(param) {
  return request({
    url: `cust/cust/address/l_get?userId=${param.userId}`,
    method: 'get',
    noNProgress: true,
  });
}
// 更新收货地址为默认
export function updateUserAddressDefault(param) {
  return request({
    url: `cust/cust/address/l_updateDefault?userId=${param.userId}&pkid=${param.pkid}`,
    method: 'get',
    noNProgress: true,
  });
}
// 删除收货地址
export function delUserAddress(param) {
  return request({
    url: `cust/cust/address/l_delete?pkid=${param.pkid}&userId=${param.userId}`,
    method: 'get',
    noNProgress: true,
  });
}

// 查询用户
export function findUser(param) {
  return request({
    url: 'cust/cust/l_findUser',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
export function searchbar(param) {
  return request({
    url: '/product-mall/searcher/p_searchbar',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
export function addCart(param) {
  return request({
    url: '/cart/l_add',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
export function info8list(param) {
  return request({
    url: '/cart/l_info8list',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
// 查询草稿列表
export function findDrafts(param) {
  return request({
    url: '/order/order/make/draft/l_findList',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
// 获取默认地址
export function getDefault(param) {
  return request({
    url: `/cust/cust/address/l_getDefault?userId=${param}`,
    method: 'get',
    data: param,
    noNProgress: true,
  });
}
export function parse4carts(param) {
  return request({
    url: '/product-mall/searcher/p_parse4carts',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
export function addToList(param) {
  return request({
    url: '/order/order/make/cart/l_adds',
    method: 'post',
    data: param,
    noNProgress: true,
  });
}
// 获取购物车数据
export function getInfoCart() {
  return request({
    url: '/order/order/make/cart/l_getInfo',
    method: 'get',
    noNProgress: true,
  });
}
// 修改选择客户
export function changeCustomer(params) {
  // id,type,draftId
  // ?userId=${id}&type=${type}&draftId=${draftId}
  return request({
    url: '/order/order/make/l_changeCustomer',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}
// 添加草稿
export function addDraft(params) {
  return request({
    url: '/order/order/make/draft/l_adds',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}
// 删除草稿
export function deleteDraft(params) {
  return request({
    url: `/order/order/make/draft/l_delete?userId=${params.userId}&draftId=${params.draftId}`,
    method: 'get',
    noNProgress: true,
  });
}
// 编辑草稿列表
export function editDetailDraft(params) {
  return request({
    url: '/order/order/make/cart/l_editDetail',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}
// 草稿编辑回显数据
export function findDetailDraft(params) {
  return request({
    url: `/order/order/make/draft/l_findDetail?userId=${params.userId}&draftId=${params.draftId}`,
    method: 'get',
    noNProgress: true,
  });
}
// 拖拽排序
export function sortDraft(params) {
  return request({
    url: '/order/order/make/l_sort',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}
// 删除明细
export function delDetailOne(params) {
  return request({
    url: `/order/order/make/l_delDetail?userId=${params.userId}&detailId=${params.detailId}&type=${params.type}`,
    method: 'get',
    noNProgress: true,
  });
}
// 创建明细
export function createOrderApi(params) {
  return request({
    url: '/order/order/make/draft/l_create',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}
// 判断存在相同商品
export function isExist(params) {
  return request({
    url: '/order/order/make/l_isExists',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}
// 纠错合并
export function updateCorrect(params) {
  return request({
    url: '/order/order/make/l_updateCorrect',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}
// 创建订单角标
export function superscript(params) {
  return request({
    url: '/order/order/make/l_superscript',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}

// 初始化清空
export function newAddInit() {
  return request({
    url: '/order/order/make/cart/l_init',
    method: 'get',
    noNProgress: true,
  });
}

// 单据类型修改
export function editType(params) {
  return request({
    url: '/order/order/make/l_editType',
    method: 'post',
    data: params,
    noNProgress: true,
  });
}

// 草稿取消编辑
export function cancelDraft(params) {
  return request({
    url: `/order/order/make/draft/edit/l_cancel?draftId=${params}`,
    method: 'post',
    noNProgress: true,
  });
}

// 草稿修改初始化
export function editInit(params) {
  return request({
    url: `/order/order/make/draft/edit/l_init?draftId=${params}`,
    method: 'post',
    noNProgress: true,
  });
}

// 草稿编辑保存
export function editSave(params) {
  return request({
    url: `/order/order/make/draft/edit/l_save?draftId=${params}`,
    method: 'post',
    noNProgress: true,
  });
}
