<template>
  <el-form
    id="login_form"
    ref="loginForm"
    class="login-form"
    status-icon
    :rules="loginRules"
    :model="loginForm"
    label-width="0"
  >
    <el-form-item prop="userName">
      <el-input
        v-model="loginForm.userName"
        size="small"
        auto-complete="off"
        placeholder="请输入用户名"
        @keyup.enter.native="handleLogin"
        @change="loadSelectCom"
      />
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="loginForm.password"
        size="small"
        :type="passwordType"
        auto-complete="off"
        placeholder="请输入密码"
        @keyup.enter.native="handleLogin"
      >
        <!--<i class="el-icon-view el-input__icon"-->
        <!--slot="suffix"-->
        <!--@click="showPassword"></i>-->
        <i
          v-if="this.loginForm.password"
          slot="suffix"
          :class="passwordType === 'password'?'iconfont iconmimabukeshi-kulian' : 'iconfont iconmimakeshihua-xiaolian_yongyuangaoliang'"
          :style="passwordType === 'password'?'color: #C0C4CC' : 'color:#28cccc'"
          style="cursor: pointer"
          @click="showPassword"
        />
      </el-input>
    </el-form-item>
    <div id="geetest" />
    <!--<transition name="error" v-if="errorText">-->
    <!--<div class="error_panel">-->
    <!--{{errorText}}-->
    <!--</div>-->
    <!--</transition>-->
    <!--    <el-form-item prop="selectCom">
      <el-select v-model="comValue" clearable placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.sshortName"
          :value="item.sshortName">
        </el-option>
        <i slot="prefix"
           class="el-icon-house"></i>
      </el-select>
    </el-form-item>-->
    <!--<el-form-item prop="code">
      <el-row :span="24">
        <el-col :span="16">
          <el-input size="small"
                    @keyup.enter.native="handleLogin"
                    :maxlength="code.len"
                    v-model="loginForm.code"
                    auto-complete="off"
                    placeholder="请输入验证码">
            <i slot="prefix"
               class="icon-yanzhengma"></i>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="login-code">
            <span class="login-code-img"
                  @click="refreshCode"
                  v-if="code.type === 'text'">{{code.value}}</span>
            <img :src="code.src"
                 class="login-code-img"
                 @click="refreshCode"
                 v-else/>
            <i class="icon-shuaxin login-code-icon" @click="refreshCode"></i>
          </div>
        </el-col>
      </el-row>

    </el-form-item>-->
    <el-form-item>
      <el-button
        v-if="refresh"
        type="primary"
        :disabled="!loginForm.userName || !loginForm.password || isCheckNow"
        size="small"
        class="login-btn"
        @click.native.prevent="handleLogin"
      >
        登录
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex';
import { randomLenNum, localStorageSetObj } from '@/assets/js/util';
import { getLoginCheck, getGeetestCode, getComListByUsername } from '../../api/user';

export default {
  name: 'Userlogin',
  data() {
    return {
      refresh: true,
      errorText: '',
      comValue: '',
      options: [],
      loginForm: {
        userName: '',
        password: '',
        comBm: '',
      },
      checked: false,
      loginRules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
        ],
      },
      loginCheck: {
        challenge: '',
        seccode: '',
        validate: '',
      },
      showCheck: false,
      passwordType: 'password',
    };
  },
  computed: {
    ...mapGetters(['tagWel']),
    isCheckNow() {
      return this.showCheck && !this.loginCheck.challenge;
    },
  },
  mounted() {
    setTimeout(() => {
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
      this.$forceUpdate;
    }, 1200);

    this.getCheckState();
  },
  methods: {
    getGeetest() {
      getGeetestCode().then((response) => {
        // if (response) {
        // 使用gt的initGeetest函数来初始化咱们的滑动验证
        // eslint-disable-next-line
        initGeetest({
          // 以下配置参数来自服务端 SDK，这是第一次验证，也就是获取到了后端的这个三个数据
          product: 'custom',
          gt: response.gt,
          challenge: response.challenge,
          offline: response.success,
          new_captcha: true,
          width: '200px',
          bg_color: 'black',
        }, (captchaObj) => {
          const geetest = document.getElementById('geetest');
          // 这里可以调用验证实例 captchaObj 的实例方法
          // 通过调用captchaObj对象的appendTo方法，给某个标签添加上滑动验证对象，就行了，看写法
          geetest.innerHTML = '';
          captchaObj.appendTo('#geetest');
          this.geetestShow = true;
          captchaObj.onSuccess(() => {
            const geetestresult = captchaObj.getValidate();
            if (geetestresult) {
              this.loginCheck.challenge = geetestresult.geetest_challenge;
              this.loginCheck.seccode = geetestresult.geetest_seccode;
              this.loginCheck.validate = geetestresult.geetest_validate;
            }
          });
        });
        // }
      });
    },
    getCheckState() {
      this.loginCheck.challenge = '';
      this.loginCheck.seccode = '';
      this.loginCheck.validate = '';
      getLoginCheck().then((response) => {
        // const { code, data } = res.data;
        // if (code === 0) {
        this.showCheck = response;
        if (response) {
          this.getGeetest();
        }
        // }
      });
    },
    loadSelectCom() {
      this.comValue = '';
    },
    showPassword() {
      this.passwordType = (this.passwordType === 'password' ? 'text' : 'password');
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        localStorage.setItem('isLoginFlag', true);
        if (valid) {
          if (this.showCheck && !this.loginCheck.challenge) {
            this.$message.closeAll();
            this.$message.warning('请点击验证按钮进行验证！');
            return;
          }
          this.$store.dispatch('LoginByUsername', this.loginForm).then((response) => {
            if (response) {
              localStorage.setItem('isLoginFlag', true);
              localStorageSetObj('companys', response.companys);
              this.$router.push({ path: this.tagWel.value });
            } else {
              this.getGeetest();
            }
          }).catch((error) => {
            const { message } = error;
            if (message) {
              this.$message.error(message);
            }
            this.getCheckState();
            this.errorText = error;
            setTimeout(() => {
              this.errorText = '';
            }, 5000);
          });
        }
      });
    },
  },
};

</script>
<style lang="scss">
#login_form {
  position: relative;

  #geetest {
    z-index: 100;
    position: absolute;
    top: 115px;
  }

  .geetest_holder.geetest_wind {
    font-size: 12px;
  }

  .geetest_holder.geetest_wind {
    width: 220px;
    min-width: 220px;
  }

  .el-input__suffix-inner {
    i {
      font-size: 13px;
      margin-right: 2px;
    }
  }

  .el-input__suffix {

    .el-icon-circle-check,
    .el-icon-circle-close {
      display: none;
    }
  }

  .error-enter-active,
  .error-leave-active {
    transition: opacity .5s
  }

  .error-enter,
  .error-leave-active {
    opacity: 0
  }

  .error_panel {
    transition: .2s;
    width: 220px;
    height: 34px;
    background-color: rgba(233, 70, 75, .1);
    border-radius: 4px;
    /*opacity: 0.1;*/
    color: #e94637;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    position: absolute;
    bottom: 89px;
  }

  .login-btn {
    width: 220px;
    height: 42px;
    background-image: linear-gradient(-22deg,
      #28cccc 0%,
      #73e2e2 100%);
    border-radius: 4px;
    margin-top: 58px;
  }
}

</style>
