import request from '@/api/axios';

// 子公司销售分析
export function getComSales(param) {
  return request({
    url: `/param/dataAnalysis/l_comSales?sareaBm=${param}`,
    method: 'get',
  });
}
// 年度销售分析
export function getAnnuaSales(param) {
  return request({
    url: `/param/dataAnalysis/l_annualSales?sareaBm=${param}`,
    method: 'get',
  });
}

// 产品线销售分析
export function getProductSales() {
  return request({
    url: '/param/dataAnalysis/l_productLineSales',
    method: 'get',
  });
}

// 产品线SKU数量
export function getProductLineSku() {
  return request({
    url: '/param/dataAnalysis/l_productLineSku',
    method: 'get',
  });
}

// 商城SKU数量
export function getMallSku() {
  return request({
    url: '/param/dataAnalysis/l_mallSku',
    method: 'get',
  });
}

// 商城SKU数量
export function getDataStatus() {
  return request({
    url: '/param/dataAnalysis/l_dataStats',
    method: 'get',
  });
}
