<template>
  <div>
    <div class="wel_container flex">
      <div class="person_panel">
        <div>
          <img
            :src="userInfo.sheadImg?userInfo.sheadImg:require('@/assets/images/user/user_img.jpg')"
            class="person_img"
          >
        </div>
        <p class="person_name">
          {{ userInfo.snickName }}
        </p>
        <p class="person_code gray">
          工号：{{ userInfo.suserCode || '-' }}
        </p>
        <div class="flex person_mess_panel">
          <div>
            <p class="gray">
              职位
            </p>
            <p class="person_position">
              {{ userInfo.sposition }}
            </p>
          </div>
          <div>
            <p class="gray">
              所属公司
            </p>
            <p class="person_com">
              {{ userInfo.scomName }}
            </p>
          </div>
        </div>
        <div class="flex city_country_panel">
          <div class="address_panel">
            <img src="@/assets/images/user/12.png">
            <span>{{ address.city }}，{{ address.country }}</span>
          </div>
          <div class="date_panel">
            <img src="@/assets/images/user/11.png">
            <span>{{ getDateWeek() }}</span>
          </div>
        </div>
        <div class="module_panel">
          <figure>
            <el-tooltip
              placement="top"
              transfer
              :disabled="!xl_a0"
            >
              <img
                src="@/assets/images/user/1.png"
                @click="toUrl('/order/orderManage',xl_a0)"
              >
              <div
                slot="content"
                style="line-height: 26px"
              >
                <p>待报价：{{ count.enquiry }}个</p>
                <p>待生成合同：{{ count.waitGenerCont }}个</p>
              </div>
            </el-tooltip>
            <div
              class="module_num"
              v-text="isZero(xl_a0,count)"
            />
            <p>订单</p>
          </figure>
          <figure @click="toUrl('/order/orderContract',xl_a1)">
            <img
              v-if="userInfo.sposition === '商务经理'"
              src="@/assets/images/user/5.png"
            >
            <img
              v-else
              src="@/assets/images/user/5.png"
            >
            <div
              class="module_num"
              v-text="isZero1(xl_a1,count.waitAuditCont)"
            />
            <p>合同审核</p>
          </figure>
          <figure>
            <img
              src="@/assets/images/user/2.png"
              @click="toUrl('/order/electronicSignature',xl_a2)"
            >
            <div
              class="module_num"
              v-text="isZero1(xl_a2,count.waitSign)"
            />
            <p>电子签章</p>
          </figure>
          <figure>
            <img
              src="@/assets/images/user/3.png"
              @click="toUrl('/customer/customerManage',xl_a3)"
            >
            <div
              class="module_num"
              v-text="isZero1(xl_a3,count.waitAuth)"
            />
            <p>客户管理</p>
          </figure>
          <figure>
            <img
              src="@/assets/images/user/4.png"
              @click="toUrl('/customer/discountset',xl_a4)"
            >
            <p>折扣设置</p>
          </figure>
          <figure>
            <img
              src="@/assets/images/user/icon-notice.png"
              @click="showNotification"
            >
            <div
              v-if="noticeTotalNum"
              class="module_num"
              v-text="noticeTotalNum"
            />
            <p>消息通知</p>
          </figure>
        </div>
      </div>
      <div class="right_content">
        <div class="activity_nav_panel">
          <div class="activity_list">
            <div
              v-for="(item,index) in titleArr6"
              :key="index"
              class="list_item"
              :style="item.surl?'cursor:pointer':''"
              @click="toActivePage(item.surl)"
              @mouseenter="setActiveItem(6*(idx-1)+index)"
              @mouseleave="changeLoop"
            >
              <div class="titleBox">
                <span class="title">{{ item.stitle }}</span>
              </div>
              <div class="mask" />
            </div>
          </div>
          <div class="direction">
            <!-- xl_disable -->
            <div
              :class="idx<=1?'xl_disable':''"
              @click="upFuc()"
            >
              <i class="el-icon-arrow-up" />
            </div>
            <div
              :class="idx >=(titleArr.length/6)?'xl_disable':''"
              @click="downFuc()"
            >
              <i class="el-icon-arrow-down" />
            </div>
          </div>
        </div>
        <img
          v-if="iconType === 'hot'"
          src="@/assets/images/user/01_03.png"
          class="bannar_icon"
        >
        <img
          v-if="iconType === 'upcoming'"
          src="@/assets/images/user/04_03.png"
          class="bannar_icon"
        >
        <img
          v-if="iconType === 'offline'"
          src="@/assets/images/user/02_03.png"
          class="bannar_icon"
        >
        <el-carousel
          ref="carousel"
          class="xl_bannar_box"
          :autoplay="autoplay"
          @change="changeCampusIndex"
        >
          <el-carousel-item
            v-for="(item,index) in dataimg"
            :key="index"
            :style="'height: 444px;' + (item.surl?'cursor:pointer':'')"
          >
            <div
              class="bannar_box"
              @click="toActivePage(item.surl)"
            >
              <img
                :src="`${URL_PREFIX}/${item.simageUrl}`"
                class="carousel-img"
              >
            </div>
          </el-carousel-item>
        </el-carousel>
        <div
          class="flex"
          style="margin-top: 27px"
        >
          <div class="notice_panel">
            <img
              src="@/assets/images/user/7.png"
              class="bg"
            >
            <div class="title">
              <img src="@/assets/images/user/6.png">
              <span>公告</span>
            </div>
            <div class="notice_content">
              <div class="notice_content_title">
                【zydmall活动推广排位赛-赛事相关活动】
              </div>
              <div class="notice_content_word">
                <!--&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;艾薇巴蒂~2021年全新福利活动劲爆来袭，赶紧关注起来别掉队！<br/>-->
                <span>① 西门子_西家车神争霸赛-CP配电赛道积分</span><span class="right_100">已结算</span><br>
                ② 西门子_西家车神争霸赛-PA工控赛道积分<span class="right_100">已结算</span><br>
                ③ 施耐德_施家开“新”锦鲤大派对第二季积分<span class="right_100">已结算</span><br>
                ④ 天正电气_越努力越幸运<span class="right_100">已结算</span><br>
                ⑤ ABB_ABB FLOW “补”习班<span class="right_100">已结算</span><br>
                ⑥ 三利电气_国货“利”刃<span class="right_100">活动未结束</span><br>
                ⑦ 施耐德_施家机友大胃王<span class="right_100">活动未结束</span><br>
              </div>
              <div class="notice_date ">
                2021/09/09
              </div>
            </div>
          </div>
          <div class="update_mess_panel">
            <img
              src="@/assets/images/user/9.png"
              class="bg"
            >
            <div class="title">
              <img src="@/assets/images/user/8.png">
              <span>更新说明</span>
            </div>
            <div class="update_content">
              <div class="update_content_title">
                【众业达积分商城】客户自主下单送积分
              </div>
              <div class="update_content_word">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;针对符合【下单送积分】的客户，商品发货后，系统自动结算积分，并计入客户账户，请知悉。 PS:下单送积分条件：①客户自主下单(代客下单不计入)；②成功激活积分商城。
              </div>
              <div class="update_date">
                2019/10/07
              </div>
              <div class="sha_bg" />
            </div>
          </div>
        </div>
      </div>
      <!--<div class="person_text_panel">-->
      <!--<div>-->
      <!--<i class="el-icon-user"></i>您好，-->
      <!--<span class="defaultColor textB">张庭恺</span>，所属公司：-->
      <!--<span class="defaultColor textB">安徽-商务主管</span>-->
      <!--</div>-->
      <!--<div class="person_nav_panel">-->
      <!--<div></div>-->
      <!--<div></div>-->
      <!--<div></div>-->
      <!--<div></div>-->
      <!--</div>-->
      <!--</div>-->
    </div>
    <!--<transition name="message">-->
    <!--<div class="message_tip_panel">-->
    <!--</div>-->
    <!--</transition>-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  getNewmessage,
  getWelCount,
  getMediaImg,
  getbrandID,
} from '@/api/user';
import { getActKey } from '@/api/customer';
import {
  checkNull,
  getUploadMessage,
  connect,
  openSocket,
} from '@/assets/js/util';
import { getUserInfo } from '@/api/login';

export default {
  name: 'Wel',
  data() {
    return {
      autoplay: true,
      count: {},
      showHisListPanel: false,
      address: {
        city: '',
        country: '',
      },
      DATA: [],
      text: '',
      actor: '',
      comBm: '021',
      show: true,
      countnum: 0,
      isText: false,
      defaultImg: [{
        simageUrl: 'zydfiles/20200416/odsdefault.png',
        txt: '默认',
      }],
      dataimg: [],
      titleArr: [],
      titleArr6: [],
      idx: 1,
      xl_a0: false,
      xl_a1: false,
      xl_a2: false,
      xl_a3: false,
      xl_a4: false,
      actkey: false,
      iconType: '',
      brndsID: [],
    };
  },
  mounted() {
    // connect();
    // openSocket()
    this.getCity();
    this.getAuCount();
    this.getMediaList();
    if (!checkNull(this.userInfo)) {
      console.log('userInfo', this.userInfo);
      // debugger
      // 目前一期商城先不调用这个方法。等后面需求完善后再重新处理。
      /* this.showNotify(); */
    }
    this.isRoot();
    this.load_brand_ID();
    // this.setUserinfo();
  },
  computed: {
    ...mapGetters(['website', 'userInfo']),
    // ws type==MALL 的消息总数
    noticeTotalNum() {
      return this.$store.state.common.totalNumForMallNotice;
    },
  },
  methods: {
    load_brand_ID() {
      getbrandID().then((response) => {
        if (response) {
          this.brndsID = response;
          this.setUserinfo();
          console.log('222', this.brndsID);
        }
      });
    },
    changeCampusIndex(index) {
      // this.$refs.remarkCarusel.setActiveItem(index)
      // (hot:热门; upcoming:即将开始; offline:线下
      if (this.titleArr[index].sactState === 'hot') {
        this.iconType = 'hot';
      }
      if (this.titleArr[index].sactState === 'upcoming') {
        this.iconType = 'upcoming';
      }
      if (this.titleArr[index].sactState === 'offline') {
        this.iconType = 'offline';
      }
    },
    upFuc() {
      // if(this.idx <=this.titleArr.length%6){
      //   return
      // }
      if (this.idx <= 1) {
        return;
      }
      this.idx--;
      this.titleArr6 = this.titleArr.slice((this.idx - 1) * 6, this.idx * 6);
    },
    downFuc() {
      if (this.idx >= this.titleArr.length / 6) {
        return;
      }
      this.idx++;
      this.titleArr6 = this.titleArr.slice((this.idx - 1) * 6, this.idx * 6);
    },
    getMediaList() {
      getMediaImg('后台管理首页').then((response) => {
        const { datas } = response;
        if (response && datas.length > 0) {
          // debugger

          this.titleArr = datas;
          this.titleArr6 = this.titleArr.slice(0, this.idx * 6);
          this.dataimg = datas;
          // console.log("this.dataimg",this.dataimg)
        } else {
          this.dataimg = this.defaultImg;
        }
      });
    },
    getAuCount() {
      getWelCount().then((response) => {
        if (response) {
          // debugger
          this.count = response;
        }
      });
    },

    toUrl(url, tab) {
      // debugger
      if (tab) {
        this.$router.push({
          path: `${url}`,
          // params: {}
        });
        return;
      }
      this.$message.error('暂无权限');
    },
    getCity() {
      const _this = this;
      AMap.plugin('AMap.Geolocation', () => {
        const geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);

        function onComplete(data) {
          // data是具体的定位信息
          console.log('data.addressComponent', data.addressComponent);
          const { city, country, province } = data.addressComponent;
          _this.address.city = city || province;
          _this.address.country = country;
        }

        function onError(data) {
          // 定位出错
          console.log('定位失败错误：', data);
          // 调用ip定位
          _this.getLngLatLocation();
        }
      });
    },
    getLngLatLocation() {
      const _this = this;
      AMap.plugin('AMap.CitySearch', () => {
        const citySearch = new AMap.CitySearch();
        citySearch.getLocalCity((status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            const { city, country, province } = result;
            _this.address.city = city || province;
            _this.address.country = '中国';
          }
        });
      });
    },
    /* showNotify() {
        getNewmessage(this.comBm).then(res => {
          const { code, data } = res.data;
          if (code === 0 && this.show) {
            this.show = !this.show;
            this.$notify.closeAll();
            this.$notify({
              position: "bottom-right",
              dangerouslyUseHTMLString: true,
              message:
                `<span class="notify_title"><i class="el-icon-bell" style="margin-right: 4px"></i>新消息</span><br>` +
                `<span class="notify_text">待审核客户(<span class="red">${data.auditNum ||
                0}</span>)个</span><br>` +
                `<span class="notify_text">新订单(<span class="red">${data.orderNum ||
                0}</span>)个</span>`,
              duration: 0
            });
          }
        });
      }, */
    getData() {
      if (this.countnum < this.DATA.length - 1) {
        this.countnum++;
      } else {
        this.countnum = 0;
      }
      this.isText = true;
      this.actor = this.DATA[this.countnum];
    },
    getDateWeek() {
      const todayDate = new Date();
      const date = todayDate.getDate();
      const month = todayDate.getMonth() + 1;
      const year = todayDate.getYear();
      let dateweek = '';
      if (navigator.appName === 'Netscape') {
        dateweek = `${dateweek + (1900 + year)}年${month}月${date}日 `;
      }
      if (navigator.appVersion.indexOf('MSIE') != -1) {
        dateweek = `${dateweek + year}年${month}月${date}日 `;
      }
      switch (todayDate.getDay()) {
        case 0:
          dateweek += '星期日';
          break;
        case 1:
          dateweek += '星期一';
          break;
        case 2:
          dateweek += '星期二';
          break;
        case 3:
          dateweek += '星期三';
          break;
        case 4:
          dateweek += '星期四';
          break;
        case 5:
          dateweek += '星期五';
          break;
        case 6:
          dateweek += '星期六';
          break;
      }
      return dateweek;
    },
    setData() {
      let num = 0;
      let count = 0;
      let active = false;
      const timeoutstart = 5000;
      const timeoutend = 1000;
      const timespeed = 10;
      setInterval(() => {
        if (this.isText) {
          if (count === this.actor.length) {
            active = true;
          } else {
            active = false;
          }
          if (active) {
            num--;
            this.text = this.actor.substr(0, num);
            if (num === 0) {
              this.isText = false;
              setTimeout(() => {
                count = 0;
                this.getData();
              }, timeoutend);
            }
          } else {
            num++;
            this.text = this.actor.substr(0, num);
            if (num === this.actor.length) {
              this.isText = false;
              setTimeout(() => {
                this.isText = true;
                count = this.actor.length;
              }, timeoutstart);
            }
          }
        }
      }, timespeed);
    },
    isRoot() {
      getUserInfo().then((response) => {
        // debugger
        const arr = [];

        for (let idx = 0; idx < response.menus.length; ++idx) {
          const item1 = response.menus[idx];

          for (let idx2 = 0; idx2 < item1.children.length; ++idx2) {
            const item2 = item1.children[idx2];
            JSON.stringify(item2, null, 2);
            arr.push(item2.id);
          }
        }

        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === 184) {
            this.xl_a0 = true;
          }
          if (arr[i] === 243) {
            this.xl_a1 = true;
          }
          if (arr[i] === 238) {
            this.xl_a2 = true;
          }
          if (arr[i] === 203) {
            this.xl_a3 = true;
          }
          if (arr[i] === 204) {
            this.xl_a4 = true;
          }
        }
      });
    },
    // 显示对应的活动图片
    toActivity(param) {
      window.open(param);
    },
    changeLoop() {
      console.log('asdasdsadas');
      this.autoplay = true;
    },
    setActiveItem(index) {
      // debugger
      this.$refs.carousel.setActiveItem(index);
      this.autoplay = false;
    },
    toActivePage(url) {
      if (!url) return;
      window.open(`${url}?login_key=${this.actkey}`, '_blank');
    },
    // 活动用户信息提供给顺军
    setUserinfo() {
      const _this = this;
      const params = {};
      params.conid = this.userInfo.suserCode;
      params.uComBm = this.userInfo.scomBm;
      params.uName = this.userInfo.snickName;
      params.uid = this.userInfo.id;
      params.brandId = this.brndsID;
      getActKey(params).then((response) => {
        if (!response) {
          this.$message.error('请联系管理员');
          return;
        }
        _this.actkey = response.login_key;
      });
    },
    isZero(boo, count) {
      if (!boo) {
        return 0;
      }
      return (parseFloat(count.enquiry) + parseFloat(count.waitGenerCont) || 0);
    },
    isZero1(boo, num) {
      if (!boo) {
        return 0;
      }
      return num;
    },
    // 显示折扣设置弾层
    showNotification() {
      if (this.noticeTotalNum === 0) {
        this.$message('当前暂无通知噢~');
      } else {
        this.$store.commit('SET_NOTIFICATION_VISIBLE', true);
      }
    },
  },
};

</script>
<style lang="scss">
.el-scrollbar__view {
  height: auto;
}

.wel_container {
  margin: 42px 30px 20px 30px;

  .right_content {
    width: 100%;
    position: relative;
    display: flex;
    margin-left: 30px;
    flex-direction: column;

    .xl_bannar_box {
      width: 83.86%;
      border-radius: 10px 0px 0px 10px;
      height: 444px
    }

    .bannar_box {
      position: relative;
    }

    .bannar_icon {
      position: absolute;
      top: -7px;
      left: -7px;
      width: 119px;
      height: 119px;
      z-index: 999;
    }

    .carousel-img {
      width: 100%;
      height: 444px;
    }

    .activity_nav_panel {
      .direction {
        width: 93px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .xl_disable {
          i {
            opacity: 0.3;
          }
        }

        div {
          cursor: pointer;
          width: 36px;
          height: 30px;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 13px;
          text-align: center;
          line-height: 30px;
        }
      }

      width: 16.14%;
      height: 444px;
      background-color: rgba(0, 0, 0, 0.85);
      color: white;
      position: absolute;
      z-index: 1000;
      top: 0;
      right: 0;
      border-radius: 0px 10px 10px 0;

      .activity_list {
        height: 357px;
        overflow: hidden;
        margin-top: 17px;

        .list_item {
          position: relative;

          .titleBox {
            height: 58px;
            display: flex;
            align-items: center;
          }

          .title {
            margin-left: 20px;
            //   position: relative;
            font-size: 16px;
            // padding-bottom: 39px;
            max-width: 165px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;

          }

          .mask {
            position: absolute;
            width: 100%;
            height: 77px;
            background: rgba(254, 119, 0, 0.2);
            left: 0;
            top: -8px;
            display: none;
          }

          .brandName {
            position: absolute;
            bottom: 17px;
            right: 11px;
            display: none;
            line-height: 20px;
            font-size: 15.4px;
          }

          img {
            transform: translateY(4px);
            margin-right: 8px;
            display: none;
          }
        }

        .list_item:hover .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          font-size: 20px;
          color: #febf00;
        }

        .list_item:first-child:hover .title {
          margin-top: 10px;
        }

        .list_item:hover .mask {
          display: block;
        }

        .history_list {
          /*opacity: 0;*/
          /*display: none;*/
          /*transform:translateY(10px);*/
          width: 229px;
          height: 222px;
          transition: 0.4s;
          background-color: #ffffff;
          border-radius: 10px;
          border: solid 2px #ffaa00;
          position: absolute;
          bottom: 35px;
          left: 19px;

          ul {
            padding: 5px 0;
            max-height: 186px;
            overflow: auto;
          }

          .history_list_item {
            cursor: pointer;
            color: #444;
            text-align: center;
            line-height: 41px;
          }

          .history_list_item:hover {
            box-shadow: 0px 6px 17px 1px rgba(255, 150, 0, 0.48);
            border-radius: 33px;
          }
        }

        .see_history_btn {
          position: absolute;
          cursor: pointer;
          width: 229px;
          height: 45px;
          z-index: 100;
          background-image: linear-gradient(90deg, #ffc000 0%, #fe7400 100%);
          box-shadow: 0px 2px 10px 0px rgba(255, 150, 0, 0.56);
          border-radius: 23px;
          line-height: 45px;
          text-align: center;
          bottom: 22px;
          left: 19px;
          //margin: 19px;
        }
      }
    }

    .el-carousel__arrow {
      top: 73%;
    }

    .notice_panel {
      margin-right: 29px;

      .notice_content_word {
        color: #909399;
        height: 150px;
        overflow: auto;
      }

      .notice_content {
        padding: 20px 8%;

        .notice_content_title {
          color: #666;
          font-size: 16px;
          text-align: center;
          font-weight: 600;
          margin: 5px 0 32px 0;
        }

        .notice_date {
          position: absolute;
          bottom: 16px;
          right: 16px;
          color: #909399;
        }

        .notice_content_word {
          color: #909399;
        }

        .right_100 {
          right: 110px;
          position: absolute;
          width: 80px;
        }
      }
    }

    .update_mess_panel {
      .update_content {
        padding: 20px 8%;

        .update_content_title {
          color: #666;
          font-size: 16px;
          text-align: center;
          font-weight: 600;
          margin: 5px 0 32px 0;
        }

        .update_date {
          position: absolute;
          bottom: 16px;
          right: 16px;
          color: #909399;
        }

        .update_content_word {
          color: #909399;
          height: 150px;
          overflow: auto;
        }
      }
    }

    .notice_panel,
    .update_mess_panel {
      position: relative;
      box-shadow: 0px 2px 32px 0px rgba(222, 222, 222, 0.86);
      position: relative;
      flex: 1;
      border-radius: 7px;
      background: white;
      min-width: 300px;
      padding: 5px 10px 10px 12px;
      height: 335px;

      .title {
        span {
          margin-left: 10px;
        }

        img {
          transform: translateY(5px);
        }
      }

      .bg {
        position: absolute;
        top: 0;
        right: 0;
      }

      .sha_bg {
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .person_panel {
    box-shadow: 0px 2px 32px 0px rgba(222, 222, 222, 0.86);
    width: 385px;
    padding-bottom: 57px;
    min-width: 385px;
    background: white;
    min-height: 600px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .module_panel {
      width: 100%;
      margin-top: 56px;

      figure {
        display: inline-block;
        margin: 20px 6px 10px 82px;
        position: relative;

        img {
          border-radius: 30px;
          transition: 0.33s;
          cursor: pointer;
        }

        .module_num {
          position: absolute;
          right: 0;
          top: -4px;
          font-size: 12px;
          border-radius: 41%;
          padding: 0px 6px;
          background: #ffd20f;
          color: #e59001;
          font-weight: bold;
        }

        img:hover {
          box-shadow: 0px 0px 11px -3px #28cccc;
        }

        p {
          text-align: center;
          color: #939393;
          font-weight: bold;
        }
      }
    }

    .city_country_panel {
      margin-top: 20px;
      width: 100%;

      .address_panel,
      .date_panel {
        text-align: left;

        img {
          //margin-right: 5px;
          transform: translate(1px, 2px);
          margin-right: 6px;
        }
      }

      .date_panel {
        margin-left: 46px;
      }

      .address_panel {
        padding-left: 40px;
      }
    }

    .person_mess_panel {
      width: 100%;
      margin-top: 20px;
      padding: 0 30px 10px 30px;
      border-bottom: 1px solid #eeeeee;

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .person_position,
    .person_com {
      font-size: 15.5px;
      line-height: 37px;
      font-weight: bold;
      color: #666;
    }

    .person_name {
      font-size: 23.6px;
      line-height: 37px;
      margin-top: 10px;
      color: #666;
      font-weight: bold;
    }

    .gray {
      color: #999999;
    }

    .person_img {
      width: 147px;
      height: 147px;
      border-radius: 50%;
      margin-top: -42px;
    }
  }

  .person_text_panel {
    letter-spacing: 2px;
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .person_nav_panel {
    display: flex;
  }
}

.notify_text {
  line-height: 34px;
  letter-spacing: 1px;
}

.notify_title {
  line-height: 34px;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 16px;
  color: #303133;
}

.el-notification__content {
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.22s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: translateY(10px);
}

.message_tip_panel {
  width: 400px;
  max-height: 354px;
  background: #fff;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  right: 0;
  min-height: 149px;
  z-index: 2999;
}

.message-enter-active,
.message-leave-active {
  transition: 0.62s;
}

.message-enter,
.message-leave-active {
  opacity: 0;
  transform: translateY(-70px);
}

.direction .el-icon-arrow-up:before {
  content: "\e6e1" !important;
}

</style>
