import request from '@/api/axios';

// 留言板查询
export function messList(query) {
  return request({
    url: '/cust/cust/brand/zone/message/l_list',
    method: 'post',
    data: query,
  });
}
